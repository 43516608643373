import React from 'react'
import Link from "react-scroll/modules/components/Link";

export const Footer = () => {
  return (
    <footer className="footer p-10 bg-base-200 text-base-content">
  <div>
    
  <Link className="text-3xl font-bold text-primary cursor-pointer" to="start"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={1250}>ScholarLink</Link>
                    <h5>Version 1.0 Beta </h5>
  </div> 
  <div>
    <span className="footer-title">Rechtliches</span> 
    <a className="link link-hover" href='/impressum'>Impressum</a> 
    <a className="link link-hover" href='/datenschutz'>Datenschutzerklärung</a>
    <a className="link link-hover" href='/agb'>AGBs</a>
    <a className="link link-hover" href='/licenses'>Lizenzen</a>
  </div>
  <div>
    <span className="footer-title">Links</span> 
    <a className="link link-hover" href='/'>Home</a> 
    <a className="link link-hover" href='/offers/a'>Angebote</a> 
    <a className="link link-hover" href='/profile'>Profil</a> 
    <a className="link link-hover" href='/createAccount'>Account erstellen</a>
    <a className="link link-hover" href='/login'>Anmelden</a>
    
  </div>
  <div>
    <span className="footer-title">ScholarLink</span> 
    <a className="link link-hover" href='/faq'>FAQ</a>
    <a className="link link-hover" href='href="mailto:elia.ritzmann@stud.altekanti.ch?subject=ScholarLink%20Support-Anfrage'>Support</a> 
    <a className="link link-hover" href='href="mailto:elia.ritzmann@stud.altekanti.ch?subject=ScholarLink%20Feedback'>Feedback</a> 
    <a className="link link-hover" href='mailto:elia.ritzmann@stud.altekanti.ch?subject=ScholarLink%20Fehler-melden'>Fehler Melden</a>
  </div>
</footer>
  )
}
