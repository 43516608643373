import React from "react";
import { Footer } from "../components/footer/Footer";
import { Navbar } from "../components/navbar/Navbar";

export const Datenschutzerklährung = () => {
  
  return (
    <div className="bg-gray-50">
      <Navbar></Navbar>
      <main>
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <h2>Datenschutzerklärung</h2>
          <p>
            Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere
            der EU-Datenschutzgrundverordnung (DSGVO), ist:
          </p>
          <br />
          <p>
            Elia Ritzmann
            <br />
            Fliederweg8
            <br />
            5040 Schöftland
          </p>
          <p>
            Telefon: 0788912222
            <br />
            E-Mail: derelia2004@gmail.com
            <br />
            WebSite: http://scholarlink.ch/{" "}
          </p>
          <p>&nbsp;</p>
          <h2>Allgemeiner Hinweis</h2>{" "}
          <p>
            Gestützt auf Artikel 13 der schweizerischen Bundesverfassung und den
            datenschutzrechtlichen Bestimmungen des Bundes (Datenschutzgesetz,
            DSG{/*DSG*/}) hat jede Person Anspruch auf Schutz ihrer Privatsphäre
            sowie auf Schutz vor Missbrauch ihrer persönlichen Daten. Die
            Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
            sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich
            und entsprechend der gesetzlichen Datenschutzvorschriften sowie
            dieser Datenschutzerklärung.
          </p>
          <p>
            In Zusammenarbeit mit unseren Hosting-Providern bemühen wir uns, die
            Datenbanken so gut wie möglich vor fremden Zugriffen, Verlusten,
            Missbrauch oder vor Fälschung zu schützen.
          </p>
          <p>
            Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B.
            bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
            Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
            nicht möglich.
          </p>
          <p>
            Durch die Nutzung dieser Website erklären Sie sich mit der Erhebung,
            Verarbeitung und Nutzung von Daten gemäss der nachfolgenden
            Beschreibung einverstanden. Diese Website kann grundsätzlich ohne
            Registrierung besucht werden. Dabei werden Daten wie beispielsweise
            aufgerufene Seiten bzw. Namen der abgerufenen Datei, Datum und
            Uhrzeit zu statistischen Zwecken auf dem Server gespeichert, ohne
            dass diese Daten unmittelbar auf Ihre Person bezogen werden.
            Personenbezogene Daten, insbesondere Name, Adresse oder
            E-Mail-Adresse werden soweit möglich auf freiwilliger Basis erhoben.
            Ohne Ihre Einwilligung erfolgt keine Weitergabe der Daten an Dritte.
          </p>
          <p>&nbsp;</p>
          <h2>Bearbeitung von Personendaten</h2>{" "}
          <p>
            Personendaten sind alle Angaben, die sich auf eine bestimmte oder
            bestimmbare Person beziehen. Eine betroffene Person ist eine Person,
            über die Personendaten bearbeitet werden. Bearbeiten umfasst jeden
            Umgang mit Personendaten, unabhängig von den angewandten Mitteln und
            Verfahren, insbesondere das Aufbewahren, Bekanntgeben, Beschaffen,
            Löschen, Speichern, Verändern, Vernichten und Verwenden von
            Personendaten.
          </p>{" "}
          <p>
            Wir bearbeiten Personendaten im Einklang mit dem schweizerischen
            Datenschutzrecht. Im Übrigen bearbeiten wir – soweit und sofern die
            EU-DSGVO anwendbar ist – Personendaten gemäss folgenden
            Rechtsgrundlagen im Zusammenhang mit Art. 6 Abs. 1 DSGVO{/*DSGVO*/}:
          </p>{" "}
          <ul>
            {" "}
            <li>
              <strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO)</strong> -
              Die betroffene Person hat ihre Einwilligung in die Verarbeitung
              der sie betreffenden personenbezogenen Daten für einen
              spezifischen Zweck oder mehrere bestimmte Zwecke gegeben.
            </li>{" "}
            <li>
              <strong>
                Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S.
                1 lit. b. DSGVO)
              </strong>{" "}
              - Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen
              Vertragspartei die betroffene Person ist, oder zur Durchführung
              vorvertraglicher Massnahmen erforderlich, die auf Anfrage der
              betroffenen Person erfolgen.
            </li>{" "}
            <li>
              <strong>
                Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c. DSGVO)
              </strong>{" "}
              - Die Verarbeitung ist zur Erfüllung einer rechtlichen
              Verpflichtung erforderlich, der der Verantwortliche unterliegt.
            </li>{" "}
            <li>
              <strong>
                Schutz lebenswichtiger Interessen (Art. 6 Abs. 1 S. 1 lit. d.
                DSGVO)
              </strong>{" "}
              - Die Verarbeitung ist erforderlich, um lebenswichtige Interessen
              der betroffenen Person oder einer anderen natürlichen Person zu
              schützen.
            </li>{" "}
            <li>
              <strong>
                Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO)
              </strong>{" "}
              - Die Verarbeitung ist zur Wahrung der berechtigten Interessen des
              Verantwortlichen oder eines Dritten erforderlich, sofern nicht die
              Interessen oder Grundrechte und Grundfreiheiten der betroffenen
              Person, die den Schutz personenbezogener Daten erfordern,
              überwiegen.
            </li>{" "}
            <li>
              <strong>
                Bewerbungsverfahren als vorvertragliches bzw. vertragliches
                Verhältnis (Art. 9 Abs. 2 lit. b DSGVO)
              </strong>{" "}
              - Soweit im Rahmen des Bewerbungsverfahrens besondere Kategorien
              von personenbezogenen Daten im Sinne des Art. 9 Abs. 1 DSGVO (z.B.
              Gesundheitsdaten, wie Schwerbehinderteneigenschaft oder ethnische
              Herkunft) bei Bewerbern angefragt werden, damit der
              Verantwortliche oder die betroffene Person die ihm bzw. ihr aus
              dem Arbeitsrecht und dem Recht der sozialen Sicherheit und des
              Sozialschutzes erwachsenden Rechte ausüben und seinen bzw. ihren
              diesbezüglichen Pflichten nachkommen kann, erfolgt deren
              Verarbeitung nach Art. 9 Abs. 2 lit. b. DSGVO, im Fall des
              Schutzes lebenswichtiger Interessen der Bewerber oder anderer
              Personen gem. Art. 9 Abs. 2 lit. c. DSGVO oder für Zwecke der
              Gesundheitsvorsorge oder der Arbeitsmedizin, für die Beurteilung
              der Arbeitsfähigkeit des Beschäftigten, für die medizinische
              Diagnostik, die Versorgung oder Behandlung im Gesundheits- oder
              Sozialbereich oder für die Verwaltung von Systemen und Diensten im
              Gesundheits- oder Sozialbereich gem. Art. 9 Abs. 2 lit. h. DSGVO.
              Im Fall einer auf freiwilliger Einwilligung beruhenden Mitteilung
              von besonderen Kategorien von Daten, erfolgt deren Verarbeitung
              auf Grundlage von Art. 9 Abs. 2 lit. a. DSGVO.
            </li>{" "}
          </ul>{" "}
          <p>
            Wir bearbeiten Personendaten für jene Dauer, die für den jeweiligen
            Zweck oder die jeweiligen Zwecke erforderlich ist. Bei länger
            dauernden Aufbewahrungspflichten aufgrund von gesetzlichen und
            sonstigen Pflichten, denen wir unterliegen, schränken wir die
            Bearbeitung entsprechend ein.
          </p>{" "}
          <p>&nbsp;</p> <h2>Massgebliche Rechtsgrundlagen</h2>{" "}
          <p>
            Nach Massgabe des Art. 13 DSGVO teilen wir Ihnen die
            Rechtsgrundlagen unserer Datenverarbeitungen mit. Sofern die
            Rechtsgrundlage in der Datenschutzerklärung nicht genannt wird, gilt
            Folgendes: Die Rechtsgrundlage für die Einholung von Einwilligungen
            ist Art. 6 Abs. 1 lit. a und Art. 7 DSGVO, die Rechtsgrundlage für
            die Verarbeitung zur Erfüllung unserer Leistungen und Durchführung
            vertraglicher Massnahmen sowie Beantwortung von Anfragen ist Art. 6
            Abs. 1 lit. b DSGVO, die Rechtsgrundlage für die Verarbeitung zur
            Erfüllung unserer rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit.
            c DSGVO, und die Rechtsgrundlage für die Verarbeitung zur Wahrung
            unserer berechtigten Interessen ist Art. 6 Abs. 1 lit. f DSGVO. Für
            den Fall, dass lebenswichtige Interessen der betroffenen Person oder
            einer anderen natürlichen Person eine Verarbeitung personenbezogener
            Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als
            Rechtsgrundlage.
          </p>{" "}
          <p>&nbsp;</p> <h2>Sicherheitsmassnahmen</h2>{" "}
          <p>
            Wir treffen nach Massgabe der gesetzlichen Vorgaben unter
            Berücksichtigung des Stands der Technik, der Implementierungskosten
            und der Art, des Umfangs, der Umstände und der Zwecke der
            Verarbeitung sowie der unterschiedlichen
            Eintrittswahrscheinlichkeiten und des Ausmasses der Bedrohung der
            Rechte und Freiheiten natürlicher Personen geeignete technische und
            organisatorische Massnahmen, um ein dem Risiko angemessenes
            Schutzniveau zu gewährleisten.
          </p>{" "}
          <p>
            Zu den Massnahmen gehören insbesondere die Sicherung der
            Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch
            Kontrolle des physischen und elektronischen Zugangs zu den Daten als
            auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der
            Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben
            wir Verfahren eingerichtet, die eine Wahrnehmung von
            Betroffenenrechten, die Löschung von Daten und Reaktionen auf die
            Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den
            Schutz personenbezogener Daten bereits bei der Entwicklung bzw.
            Auswahl von Hardware, Software sowie Verfahren entsprechend dem
            Prinzip des Datenschutzes, durch Technikgestaltung und durch
            datenschutzfreundliche Voreinstellungen.
          </p>{" "}
          <p>&nbsp;</p> <h2>Übermittlung von personenbezogenen Daten</h2>{" "}
          <p>
            Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es
            vor, dass die Daten an andere Stellen, Unternehmen, rechtlich
            selbstständige Organisationseinheiten oder Personen übermittelt oder
            sie ihnen gegenüber offengelegt werden. Zu den Empfängern dieser
            Daten können z.B. mit IT-Aufgaben beauftragte Dienstleister oder
            Anbieter von Diensten und Inhalten, die in eine Webseite eingebunden
            werden, gehören. In solchen Fall beachten wir die gesetzlichen
            Vorgaben und schliessen insbesondere entsprechende Verträge bzw.
            Vereinbarungen, die dem Schutz Ihrer Daten dienen, mit den
            Empfängern Ihrer Daten ab.
          </p>{" "}
          <p>&nbsp;</p> <h2>Datenverarbeitung in Drittländern</h2>{" "}
          <p>
            Sofern wir Daten in einem Drittland (d.h., ausserhalb der
            Europäischen Union (EU), des Europäischen Wirtschaftsraums (EWR))
            verarbeiten oder die Verarbeitung im Rahmen der Inanspruchnahme von
            Diensten Dritter oder der Offenlegung bzw. Übermittlung von Daten an
            andere Personen, Stellen oder Unternehmen stattfindet, erfolgt dies
            nur im Einklang mit den gesetzlichen Vorgaben.
          </p>{" "}
          <p>
            Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich oder
            gesetzlich erforderlicher Übermittlung, verarbeiten wir die Daten
            nur in Drittländern mit einem anerkannten Datenschutzniveau,
            vertraglicher Verpflichtung durch sogenannte Standardschutzklauseln
            der EU-Kommission, beim Vorliegen von Zertifizierungen oder
            verbindlichen internen Datenschutzvorschriften (Art. 44 bis 49
            DSGVO, Informationsseite der EU-Kommission:
            https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de).
          </p>{" "}
          <p>&nbsp;</p> <h2>Datenschutzerklärung für Cookies</h2>{" "}
          <p>
            Diese Website verwendet Cookies. Cookies sind Textdateien, die Daten
            von besuchten Websites oder Domains enthalten und von einem Browser
            auf dem Computer des Benutzers gespeichert werden. Ein Cookie dient
            in erster Linie dazu, die Informationen über einen Benutzer während
            oder nach seinem Besuch innerhalb eines Onlineangebotes zu
            speichern. Zu den gespeicherten Angaben können z.B. die
            Spracheinstellungen auf einer Webseite, der Loginstatus, ein
            Warenkorb oder die Stelle, an der ein Video geschaut wurde, gehören.
            Zu dem Begriff der Cookies zählen wir ferner andere Technologien,
            die die gleichen Funktionen wie Cookies erfüllen (z.B. wenn Angaben
            der Nutzer anhand pseudonymer Onlinekennzeichnungen gespeichert
            werden, auch als "Nutzer-IDs" bezeichnet)
          </p>{" "}
          <p>Die folgenden Cookie-Typen und Funktionen werden unterschieden:</p>{" "}
          <ul>
            {" "}
            <li>
              <strong>
                Temporäre Cookies (auch: Session- oder Sitzungs-Cookies)
              </strong>
              : Temporäre Cookies werden spätestens gelöscht, nachdem ein Nutzer
              ein Online-Angebot verlassen und seinen Browser geschlossen hat.
            </li>{" "}
            <li>
              <strong>Permanente Cookies</strong>: Permanente Cookies bleiben
              auch nach dem Schliessen des Browsers gespeichert. So kann
              beispielsweise der Login-Status gespeichert oder bevorzugte
              Inhalte direkt angezeigt werden, wenn der Nutzer eine Website
              erneut besucht. Ebenso können die Interessen von Nutzern, die zur
              Reichweitenmessung oder zu Marketingzwecken verwendet werden, in
              einem solchen Cookie gespeichert werden.
            </li>{" "}
            <li>
              <strong>First-Party-Cookies</strong>: First-Party-Cookies werden
              von uns selbst gesetzt.
            </li>{" "}
            <li>
              <strong>Third-Party-Cookies (auch: Drittanbieter-Cookies)</strong>
              : Drittanbieter-Cookies werden hauptsächlich von Werbetreibenden
              (sog. Dritten) verwendet, um Benutzerinformationen zu verarbeiten.
            </li>{" "}
            <li>
              <strong>
                Notwendige (auch: essenzielle oder unbedingt erforderliche)
                Cookies
              </strong>
              : Cookies können zum einen für den Betrieb einer Webseite
              unbedingt erforderlich sein (z.B. um Logins oder andere
              Nutzereingaben zu speichern oder aus Gründen der Sicherheit).
            </li>{" "}
            <li>
              <strong>
                Statistik-, Marketing- und Personalisierung-Cookies
              </strong>
              : Ferner werden Cookies im Regelfall auch im Rahmen der
              Reichweitenmessung eingesetzt sowie dann, wenn die Interessen
              eines Nutzers oder sein Verhalten (z.B. Betrachten bestimmter
              Inhalte, Nutzen von Funktionen etc.) auf einzelnen Webseiten in
              einem Nutzerprofil gespeichert werden. Solche Profile dienen dazu,
              den Nutzern z.B. Inhalte anzuzeigen, die ihren potenziellen
              Interessen entsprechen. Dieses Verfahren wird auch als "Tracking",
              d.h., Nachverfolgung der potenziellen Interessen der Nutzer
              bezeichnet. Soweit wir Cookies oder "Tracking"-Technologien
              einsetzen, informieren wir Sie gesondert in unserer
              Datenschutzerklärung oder im Rahmen der Einholung einer
              Einwilligung.
            </li>{" "}
          </ul>{" "}
          <p>
            Hinweise zu Rechtsgrundlagen: Auf welcher Rechtsgrundlage wir Ihre
            personenbezogenen Daten mithilfe von Cookies verarbeiten, hängt
            davon ab, ob wir Sie um eine Einwilligung bitten. Falls dies
            zutrifft und Sie in die Nutzung von Cookies einwilligen, ist die
            Rechtsgrundlage der Verarbeitung Ihrer Daten die erklärte
            Einwilligung. Andernfalls werden die mithilfe von Cookies
            verarbeiteten Daten auf Grundlage unserer berechtigten Interessen
            (z.B. an einem betriebswirtschaftlichen Betrieb unseres
            Onlineangebotes und dessen Verbesserung) verarbeitet oder, wenn der
            Einsatz von Cookies erforderlich ist, um unsere vertraglichen
            Verpflichtungen zu erfüllen.
          </p>{" "}
          <p>
            Speicherdauer: Sofern wir Ihnen keine expliziten Angaben zur
            Speicherdauer von permanenten Cookies mitteilen (z. B. im Rahmen
            eines sog. Cookie-Opt-Ins), gehen Sie bitte davon aus, dass die
            Speicherdauer bis zu zwei Jahre betragen kann.
          </p>{" "}
          <p>
            Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out): Abhängig
            davon, ob die Verarbeitung auf Grundlage einer Einwilligung oder
            gesetzlichen Erlaubnis erfolgt, haben Sie jederzeit die Möglichkeit,
            eine erteilte Einwilligung zu widerrufen oder der Verarbeitung Ihrer
            Daten durch Cookie-Technologien zu widersprechen (zusammenfassend
            als "Opt-Out" bezeichnet). Sie können Ihren Widerspruch zunächst
            mittels der Einstellungen Ihres Browsers erklären, z.B., indem Sie
            die Nutzung von Cookies deaktivieren (wobei hierdurch auch die
            Funktionsfähigkeit unseres Onlineangebotes eingeschränkt werden
            kann). Ein Widerspruch gegen den Einsatz von Cookies zu Zwecken des
            Onlinemarketings kann auch mittels einer Vielzahl von Diensten, vor
            allem im Fall des Trackings, über die Webseiten
            https://optout.aboutads.info und https://www.youronlinechoices.com/
            erklärt werden. Daneben können Sie weitere Widerspruchshinweise im
            Rahmen der Angaben zu den eingesetzten Dienstleistern und Cookies
            erhalten.
          </p>{" "}
          <p>
            Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung: Wir
            setzen ein Verfahren zum Cookie-Einwilligungs-Management ein, in
            dessen Rahmen die Einwilligungen der Nutzer in den Einsatz von
            Cookies, bzw. der im Rahmen des
            Cookie-Einwilligungs-Management-Verfahrens genannten Verarbeitungen
            und Anbieter eingeholt sowie von den Nutzern verwaltet und
            widerrufen werden können. Hierbei wird die Einwilligungserklärung
            gespeichert, um deren Abfrage nicht erneut wiederholen zu müssen und
            die Einwilligung entsprechend der gesetzlichen Verpflichtung
            nachweisen zu können. Die Speicherung kann serverseitig und/oder in
            einem Cookie (sogenanntes Opt-In-Cookie, bzw. mithilfe
            vergleichbarer Technologien) erfolgen, um die Einwilligung einem
            Nutzer, bzw. dessen Gerät zuordnen zu können. Vorbehaltlich
            individueller Angaben zu den Anbietern von
            Cookie-Management-Diensten gelten die folgenden Hinweise: Die Dauer
            der Speicherung der Einwilligung kann bis zu zwei Jahren betragen.
            Hierbei wird ein pseudonymer Nutzer-Identifikator gebildet und mit
            dem Zeitpunkt der Einwilligung, Angaben zur Reichweite der
            Einwilligung (z. B. welche Kategorien von Cookies und/oder
            Diensteanbieter) sowie dem Browser, System und verwendeten Endgerät
            gespeichert.
          </p>{" "}
          <ul>
            {" "}
            <li>
              Verarbeitete Datenarten: Nutzungsdaten (z.B. besuchte Webseiten,
              Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten
              (z.B. Geräte-Informationen, IP-Adressen).
            </li>{" "}
            <li>
              Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer von
              Onlinediensten).
            </li>{" "}
            <li>
              Rechtsgrundlagen: Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO),
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
            </li>{" "}
          </ul>{" "}
          <p>&nbsp;</p>
          <h2>Änderungen</h2>
          <p>
            Wir können diese Datenschutzerklärung jederzeit ohne Vorankündigung
            anpassen. Es gilt die jeweils aktuelle, auf unserer Website
            publizierte Fassung. Soweit die Datenschutzerklärung Teil einer
            Vereinbarung mit Ihnen ist, werden wir Sie im Falle einer
            Aktualisierung über die Änderung per E-Mail oder auf andere
            geeignete Weise informieren.
          </p>
          <p>&nbsp;</p>
          <h2>Fragen an den Datenschutzbeauftragten</h2>{" "}
          <p>
            Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine
            E-Mail oder wenden Sie sich direkt an die für den Datenschutz zu
            Beginn der Datenschutzerklärung aufgeführten, verantwortlichen
            Person in unserer Organisation.
          </p>
          <p>&nbsp;</p>
          {/*ACHTUNG: Wenn Sie die Quelle ohne Erlaubnis von SwissAnwalt entfernen, dann begehen Sie eine Urheberrechtsverletzung welche in jedem Fall unter Kostenfolge geahndet wird.*/}
          <br />
          Quelle:{" "}
          <a href="https://www.swissanwalt.ch" target="_blank" rel="noopener">
            SwissAnwalt
          </a>
          <p />
          {/*Bitte beachten Sie die AGB von SwissAnwalt betreffend allfällig anfallenden Kosten bei Weglassen der Quelle!*/}
        </div>
      </main>
      <Footer></Footer>
    </div>
  );
};
