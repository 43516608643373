import { createContext, useContext, useEffect, useState } from "react";

//Firebase Auth
import { useAuth } from "./AuthContext";

//Firestore
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
} from "firebase/firestore";

import { firestore } from "../config/firebase";

//Create Context
const innerDatabaseContext = createContext();

export function useInnerDatabase() {
  return useContext(innerDatabaseContext);
}

export const InnerDatabaseContextProvider = ({ children }) => {
    const {user} = useAuth()

    const [myRequests, setMyRequests] = useState([])
    const [tutorRequests, setTutorRequests] = useState([])
    const [userData, setUserData] = useState([])


  const myRequestsRef = query(
    collection(firestore, "requests"), where("uid", "==", user.uid)
  );

  const tutorRequestsRef = query(
    collection(firestore, "requests"), where("tutorId", "==", user.uid)
  );

  const userDataRef = doc(firestore, "users", user.uid)


  useEffect(
    () =>
      onSnapshot(myRequestsRef, (snapshot) => {
        setMyRequests([]);
        setMyRequests(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
        console.log("myRequests read " + new Date());
      }),
    []
  );

  useEffect(
    () =>
      onSnapshot(tutorRequestsRef, (snapshot) => {
        setTutorRequests([]);
        setTutorRequests(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
        console.log("tutorRequests read " + new Date());
      }),
    []
  );

  useEffect(
    () =>
      onSnapshot(userDataRef, (doc) => {
        setUserData([]);
        setUserData(
            { ...doc.data(), id: doc.id }
        );
        console.log("userData read " + new Date());
      }),

    []
  );

  
  const value = {
    myRequests,
    tutorRequests,
    userData
  };

  return (
    <innerDatabaseContext.Provider value={value}>
      {children}
    </innerDatabaseContext.Provider>
  );
};