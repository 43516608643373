import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

import { DisplayUser } from "./DisplayUser";

export const Navbar = () => {
  const navigate = useNavigate();
  
  const {user} = useAuth()

  

  return (
    <header className="relative py-4 md:py-6 ">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 ">
        <div className="flex items-center justify-between">
          <div className="flex-shrink-0 flex gap-1 ">
            <button
              onClick={() => navigate("/")}
              title=""
              className="flex rounded outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
            >
              <h1 className="text-3xl font-bold text-primary">ScholarLink</h1>
            </button>
            <h5 className="text-yellow-500 font-semibold hidden md:block">Beta</h5>
          </div>
          <div className="flex lg:ml-16 lg:items-center lg:justify-center lg:space-x-10">
            <div className="hidden lg:block">
              <div className="flex items-center space-x-12">
                <a
                  onClick={() => navigate("/offers/a", {state: {subjectList: []}})}
                  title=""
                  className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2 cursor-pointer"
                >
                  {" "}
                  Angebote{" "}
                </a>
                <a
                  onClick={() => navigate("/faq")}
                  title=""
                  className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2 cursor-pointer"
                >
                  {" "}
                  FAQ{" "}
                </a>
              </div>
              
            </div>
            
            <div className="w-px h-5 bg-gray-300 hidden lg:block"></div>
            {user ? (
              <DisplayUser></DisplayUser>
            ) : (
              <div className="flex items-center justify-end">
                <a
                  onClick={() => navigate("/login")}
                  title=""
                  className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
                >
                  {" "}
                  Anmelden{" "}
                </a>
                <div className="hidden md:block">
                  <button
                    className="btn btn-outline btn-primary ml-8 "
                    onClick={() => navigate("/createAccount")}
                  >
                    Mitglied werden
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};
