import React from "react";
import { useState } from "react";
import { Footer } from "../components/footer/Footer";
import { Navbar } from "../components/navbar/Navbar";

const FAQ = () => {
  const [faq, setFaq] = useState([
    {
      question: "Was ist ScholarLink?",
      answer:
        "ScholarLink ist eine Online-Plattform für Schüler und Tutoren, die Nachhilfe suchen oder anbieten. Sie bringt Schüler mit qualifizierten Tutoren zusammen, die ihren Bedürfnissen entsprechen. Es ermöglicht Schülern, Nachhilfelehrer zu finden und zu buchen und es ermöglicht qualifizierten Tutoren, ihre Dienste anzubieten. Auf ScholarLink gibt es eine breite Palette an Fächern und Schulstufen, die es Schülern und Tutoren ermöglicht, passende Angebote und Anfragen zu finden.",
      open: false,
    },
    {
      question: "Wo findet die Nachhilfe statt?",
      answer:
        "Die Nachhilfe auf ScholarLink findet in der Regel online statt, über eine Video-Konferenz- oder Chat-Plattform. So können Schüler und Tutoren aus der ganzen Schweiz miteinander in Kontakt treten und Nachhilfe erhalten, ohne dass sie sich an einem bestimmten Ort treffen müssen. Es besteht auch die Möglichkeit, dass die Nachhilfe vor Ort stattfindet, je nachdem, was für die Schüler und Tutoren am besten passt. ScholarLink stellt dabei jedoch keine Räumlichkeiten dar und die Tutoren müssen sich mit den Schülern selber organisieren.",
      open: false,
    },
    {
      question: "Wie kann ich Nachhilfe anbieten?",
      answer:
        "Um Nachhilfe auf ScholarLink anzubieten, musst du zunächst ein Tutoren-Profil erstellen. Dazu musst du einige Informationen über dich selbst eingeben, wie z.B. dein Alter, Schulstufe und Erfahrungen. Sobald dein Profil erstellt und verifiziert ist, kannst du Angebote für Nachhilfe-Sessions erstellen und auf Anfragen von Schülern reagieren. Es ist wichtig, dass du regelmäßig online bist oder dein Email-Postfach überprüfst, so dass du keine Anfragen verpasst. Es ist auch wichtig das du dir die Richtlinien der Plattform durchliest und einhältst, um sicherzustellen, dass du die Anforderungen erfüllst und keine Regeln verletzt.",
      open: false,
    },
    {
      question: "Was bedeutet es, wenn ein Tutor verifiziert ist?",
      answer:
        "Wenn ein Tutor auf ScholarLink verifiziert ist, bedeutet das, dass er oder sie eine Überprüfung durch ScholarLink bestanden hat und bestätigt wurde, dass er oder sie qualifiziert ist, Nachhilfe zu geben. Ein verifizierter Tutor wird von Schülern und Eltern als vertrauenswürdig und qualifiziert betrachtet, was dazu beitragen kann, dass er oder sie mehr Anfragen für Nachhilfe-Sessions erhält. Es gibt auch die Möglichkeit, dass verifizierte Tutoren von der Plattform bevorzugt behandelt werden, wie z.B. höhere Sichtbarkeit in den Suchergebnissen.",
      open: false,
    },
    {
      question: "Wie kann ich meinen Account verifizieren?",
      answer:
        "Um deinen Account auf ScholarLink zu verifizieren, musst du bestimmte Informationen und Unterlagen bereitstellen, die von dem Plattformbetreiber überprüft werden. Die genauen Anforderungen für die Verifizierung können von Zeit zu Zeit variieren, aber in der Regel musst du mindestens folgendes bereitstellen: Ein gültiges Ausweisdokument und Qualifikationen (z.B. ein Schul-Zeugnis). WICHTIG: Zurzeit ist es nur begrenzt möglich deinen Account zu verifizieren. Wir arbeiten gerade daran eine gute und effiziente Lösung zu implementieren.",
      open: false,
    },
  ]);

  const toggleFaq = (index) => {
    setFaq(
      faq.map((item, i) => {
        if (i === index) {
          item.open = !item.open;
        } else {
          item.open = false;
        }

        return item;
      })
    );
  };

  return (
    <div className="bg-gray-50 h-screen">
      <Navbar></Navbar>
      <section className="py-10sm:py-16 lg:py-24">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
              Häufig gestellte Fragen (FAQ)
            </h2>
            <p className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-600">
              Auf dieser Seite findest du Antworten zu häufig gestellten Fragen
              rund um ScholarLink.
            </p>
          </div>

          <div className="max-w-3xl mx-auto mt-8 space-y-4 md:mt-16">
            {faq.map((item, index) => (
              <div
                key={index}
                className="transition-all duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-gray-50"
              >
                <button
                  type="button"
                  className="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                  onClick={() => toggleFaq(index)}
                >
                  <span className="flex text-lg font-semibold text-black">
                    {" "}
                    {item.question}{" "}
                  </span>

                  <svg
                    className={`w-6 h-6 text-gray-400 ${
                      item.open ? "rotate-180" : ""
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>

                <div
                  className={`${
                    item.open ? "block" : "hidden"
                  } px-4 pb-5 sm:px-6 sm:pb-6`}
                >
                  <p dangerouslySetInnerHTML={{ __html: item.answer }}></p>
                </div>
              </div>
            ))}
          </div>

          <p className="text-center text-gray-600 textbase mt-9">
            Du hast weitere Fragen?{" "}
            <a
              href="mailto:elia.ritzmann@stud.altekanti.ch?subject=Frage%20ScholarLink"
              title=""
              className="font-medium text-blue-600 transition-all duration-200 hover:text-blue-700 focus:text-blue-700 hover:underline"
            >
              kontaktiere uns
            </a>
          </p>
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
};

export default FAQ;
