import React from "react";
import { Footer } from "../components/footer/Footer";
import { Navbar } from "../components/navbar/Navbar";

export const Impressum = () => {
  return (
    <div>
      <Navbar></Navbar>
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <p>
          <strong>
            <i>Kontakt-Adresse</i>
          </strong>
        </p>
        <p>
          Elia Ritzmann
          <br />
          Fliederweg 8<br />
          5040 Schöftland
          <br />
          Schweiz
        </p>
        <p>
          E-Mail:
          <br />
          elia.ritzmann@stud.altekanti.ch
        </p>
        <br />
        <br />
        <p>
          <strong>
            <i>Haftungsausschluss</i>
          </strong>
        </p>
        Der Autor übernimmt keinerlei Gewähr hinsichtlich der inhaltlichen
        Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit und
        Vollständigkeit der Informationen.
        <p />
        <p>
          Haftungsansprüche gegen den Autor wegen Schäden materieller oder
          immaterieller Art, welche aus dem Zugriff oder der Nutzung bzw.
          Nichtnutzung der veröffentlichten Informationen, durch Missbrauch der
          Verbindung oder durch technische Störungen entstanden sind, werden
          ausgeschlossen.
        </p>
        <p>
          Alle Angebote sind unverbindlich. Der Autor behält es sich
          ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne
          besondere Ankündigung zu verändern, zu ergänzen, zu löschen oder die
          Veröffentlichung zeitweise oder endgültig einzustellen.
        </p>
        <br />
        <br />
        <p>
          <strong>
            <i>Haftungsausschluss für Links</i>
          </strong>
        </p>
        <p>
          Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres
          Verantwortungsbereichs. Es wird jegliche Verantwortung für solche
          Webseiten abgelehnt. Der Zugriff und die Nutzung solcher Webseiten
          erfolgen auf eigene Gefahr des jeweiligen Nutzers.
        </p>
        <br />
        <br />
        <p>
          <strong>
            <i>Urheberrechte</i>
          </strong>
        </p>
        <p>
          Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder
          anderen Dateien auf dieser Website, gehören ausschliesslich{" "}
          <strong>Elia Ritzmann</strong> oder den speziell genannten
          Rechteinhabern. Für die Reproduktion jeglicher Elemente ist die
          schriftliche Zustimmung des Urheberrechtsträgers im Voraus einzuholen.
        </p>
        <br />
        {/*ACHTUNG: Wenn Sie die Quelle ohne Erlaubnis von SwissAnwalt entfernen, dann begehen Sie eine Urheberrechtsverletzung welche in jedem Fall geahndet wird.*/}
        Quelle:{" "}
        <a href="https://www.swissanwalt.ch" target="_blank" rel="noopener">
          SwissAnwalt
        </a>
        {/*Bitte beachten Sie die AGB von SwissAnwalt betreffend allf&auml;llig anfallenden Kosten bei Weglassen der Quelle!*/}
      </div>
      <Footer></Footer>
    </div>
  );
};
