import { createContext, useContext, useEffect, useState } from "react";

//Firebase connection
import { auth, firestore } from "../config/firebase";

//Firebase functions
import {
  signOut,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { doc, FieldValue, getDoc, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";

//Create Context
const userAuthContext = createContext();

export function useAuth() {
  return useContext(userAuthContext);
}

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  const resetPassword = (email) => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        // ..
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorMessage);
      });
  };

  const signIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const logOut = () => {
    return signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      await setUser(currentUser);

      //only update PublicUser if there is a user
      if (currentUser) {
        //update user information to database
        try {
          await updateDoc(doc(firestore, "users", currentUser.uid), {
            email: currentUser.email,
            lastOnline: serverTimestamp(),
          });
        } catch (error) {
          console.log(error);
        }
        
        const docRef = doc(firestore, "users", currentUser.uid);
        const docSnap = await (await getDoc(docRef)).data()

        console.log(docSnap)

        try {
          await updateDoc(doc(firestore, "publicUsers", currentUser.uid), {
            lastOnline: serverTimestamp(),
            schoolLevel: docSnap.schoolLevel,
            firstname: docSnap.firstname,
            surname: docSnap.surname,
            verified: docSnap.verified,
            tutor: docSnap.tutor
          });
        } catch (error) {
          console.log(error);
        }
        
      }

      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const value = {
    user,
    logOut,
    signIn,
    resetPassword,
  };

  return (
    <userAuthContext.Provider value={value}>
      {!loading && children}
    </userAuthContext.Provider>
  );
}
