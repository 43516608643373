import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Navbar } from "../components/navbar/Navbar";
import { Offer } from "../components/offers/Offer";
import { OfferDescription } from "../components/offers/OfferDescription";
import { useDatabase } from "../context/DatabaseContext";

export const Offers = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const { offers } = useDatabase();
  const [filter, setFilter] = useState([]);
  const [subjectFilter, setSubjectFilter] = useState([]);

  const [showDescription, setShowDescription] = useState(false);

  const { state } = useLocation();

  useEffect(() => {
    try {
      if (state == null || state.subjectList == null) {
        setSubjectFilter(subjectFilter);
      } else {
        const { subjectList } = state;

        if (subjectList?.length == 0) {
          setSubjectFilter(subjects);
        } else {
          setSubjectFilter(subjectList);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [state]);


  useEffect(() => {
    setFilter(places);
  }, []);


  const places = [
    "Aargau",
    "Appenzell Innerrhoden",
    "Appenzell Ausserrhoden",
    "Basel-Landschaft",
    "Basel-Stadt",
    "Bern",
    "Freiburg",
    "Genf",
    "Glarus",
    "Graubünden",
    "Jura",
    "Luzern",
    "Neuenburg",
    "Nidwalden",
    "Obwalden",
    "St. Gallen",
    "Schaffhausen",
    "Solothurn",
    "Schwyz",
    "Thurgau",
    "Tessin",
    "Uri",
    "Waadt",
    "Wallis",
    "Zug",
    "Zürich",
    "online",
  ];

  const subjects = [
    "Mathematik",
    "Informatik",
    "Finanz und Rechnungswesen",
    "Chemie",
    "Sprachen",
    "Wirtschaft",
    "Physik",
    "Biologie",
    "Andere",
  ];

  let elements = [];

  let filteredOffers = offers.filter((o) => filter.includes(o.place));

  let onlineOffers = [];

  if (filter.includes("online")) {
    onlineOffers = offers.filter((o) => o.online === true);
  }

  filteredOffers = mergeArrays(filteredOffers, onlineOffers);
  filteredOffers = filteredOffers.filter((o) =>
    subjectFilter.includes(o.subject)
  );

  if (!filteredOffers.some((e) => e.id === id) && filteredOffers.length > 0) {
    navigate("/offers/" + filteredOffers[0].id, {
      state: { subjectList: state?.subjectList },
    });
  }


  function mergeArrays(array1, array2) {
    // Erstelle ein leeres Ergebnis-Array
    const result = [];

    // Erstelle ein Set aus dem ersten Array
    const set1 = new Set(array1);

    // Iteriere über das zweite Array und füge jedes Element dem Set hinzu
    for (const element of array2) {
      set1.add(element);
    }

    // Iteriere über das Set und füge jedes Element dem Ergebnis-Array hinzu
    for (const element of set1) {
      result.push(element);
    }

    return result;
  }

  function toggleFilterValue(value, toggle) {
    if (toggle) {
      // Wenn toggle true ist, füge den Wert dem Array hinzu, falls er noch nicht enthalten ist
      if (!filter.includes(value)) {
        setFilter([...filter, value]);
      }
    } else {
      // Wenn toggle false ist, entferne den Wert aus dem Array, falls er enthalten ist
      const index = filter.indexOf(value);
      if (index > -1) {
        setFilter(filter.filter((v) => v !== value));
      }
    }

    return [filter, toggleFilterValue];
  }

  function toggleSubjectFilterValue(value, toggle) {
    if (toggle) {
      // Wenn toggle true ist, füge den Wert dem Array hinzu, falls er noch nicht enthalten ist
      if (!subjectFilter.includes(value)) {
        setSubjectFilter([...subjectFilter, value]);
      }
    } else {
      // Wenn toggle false ist, entferne den Wert aus dem Array, falls er enthalten ist
      const index = subjectFilter.indexOf(value);
      if (index > -1) {
        setSubjectFilter(subjectFilter.filter((v) => v !== value));
      }
    }

    return [subjectFilter, toggleSubjectFilterValue];
  }

  for (let index = 0; index < filteredOffers.length; index++) {
    elements.push(
      <Offer
        data={filteredOffers[index]}
        key={index + filteredOffers[index].title + subjectFilter.toString()}
        setShowDescription={setShowDescription}
      ></Offer>
    );
  }

  //console.log(filter);
  //console.log(elements);


  return (
    <div className="md:bg-gray-50 w-full flex flex-col min-h-screen ">
      <Navbar></Navbar>
      <div className="hidden md:block h-full">
        <main className="flex px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 w-full">
          <div className="w-2/5 flex flex-col">
            <div className="flex justify-between">
              <h4 className="text-gray-500 text-sm">
                {elements.length} Ergebnisse
              </h4>
              <label
                htmlFor="filter-modal"
                className="btn btn-xs btn-ghost gap-2 mr-1 "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
                  />
                </svg>
                Filter
              </label>
            </div>
            {elements}
            
          </div>

          <div className="divider divider-horizontal"></div>
          <div className="w-full sticky top-0 h-full">
            <OfferDescription
              id={id}
              setShowDescription={setShowDescription}
              result={true}
            ></OfferDescription>
          </div>
        </main>
      </div>
      {/*
      Mobile view
       */}
      <div className=" md:hidden">
        <main className="flex px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 w-full flex-grow">
          {!showDescription ? (
            <div className="w-full">
              <div className="flex justify-between">
                <h4 className="text-gray-500 text-sm">
                  {elements.length} Ergebnisse
                </h4>
                <label
                  htmlFor="filter-modal"
                  className="btn btn-xs btn-ghost gap-2 mr-1 "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
                    />
                  </svg>
                  Filter
                </label>
              </div>

              {elements}
            </div>
          ) : (
            <div className="w-full">
              <OfferDescription
                id={id}
                setShowDescription={setShowDescription}
              ></OfferDescription>
            </div>
          )}
        </main>
      </div>
      <input type="checkbox" id="filter-modal" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box w-11/12 max-w-5xl">
          <label
            htmlFor="filter-modal"
            className="btn btn-sm btn-circle absolute right-2 top-2"
          >
            ✕
          </label>
          <h3 className="text-lg font-bold">Filter</h3>
          <div className="flex flex-col md:flex-row justify-around">
            <div>
              <div className="divider">Orte</div>
              <div className="grid grid-cols-2 gap-2">
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    filter.length > 0 ? setFilter([]) : setFilter(places);
                  }}
                >
                  Alles
                </button>
                {places.map((place) => (
                  <div key={place} className="flex gap-1 items-center">
                    <input
                      type="checkbox"
                      checked={filter.includes(place)}
                      onChange={(e) =>
                        toggleFilterValue(place, e.target.checked)
                      }
                      className="checkbox checkbox-primary"
                    />
                    <h4 className="font-semibold">{place}</h4>
                  </div>
                ))}
              </div>
            </div>
            <div className="divider divider-horizontal"></div>
            <div>
              <div className="divider">Fächer</div>
              <div className="grid grid-cols-1 gap-2">
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    subjectFilter.length > 0
                      ? setSubjectFilter([])
                      : setSubjectFilter(subjects);
                  }}
                >
                  Alles
                </button>
                {subjects.map((subject) => (
                  <div key={subject} className="flex gap-1 items-center">
                    <input
                      type="checkbox"
                      checked={subjectFilter.includes(subject)}
                      onChange={(e) =>
                        toggleSubjectFilterValue(subject, e.target.checked)
                      }
                      className="checkbox checkbox-primary"
                    />
                    <h4 className="font-semibold">{subject}</h4>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="modal-action">
            <label htmlFor="filter-modal" className="btn btn-primary">
              Schliessen
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};
