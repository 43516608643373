import React from "react";

export const TutorCard = ({ tutor }) => {
  return (
    <div className="card bg-white mt-2 md:mt-0 md:w-56">
      <div className="card-body ">
        <div className="flex md:flex-col items-center">
          <img
            src={
              "https://avatars.dicebear.com/api/croodles/" + tutor.id + ".svg"
            }
            alt="avatar"
            className="h-24 w-24 rounded-md"
          />
          <div className="ml-4 md:ml-0 md:flex md:flex-col md:items-center">
            <h1 className="text-lg font-bold md:mt-1 md:text-center">
              {tutor.firstname + " " + tutor.surname}
            </h1>
            <h3 className="text-gray-500 -my-2">Tutor</h3>
            <div className="divider"></div>
            <h3 className="-mt-3 text-sm">Mitglied seit:</h3>
            <h3 className="text-xs font-semibold">
              {tutor.creationDate.toDate().toLocaleDateString("en-GB")}
            </h3>
            {tutor.schoolLevel && <div>
            <h3 className=" text-sm">Schulstufe:</h3>
            <h3 className=" text-xs -mt-1 font-semibold">
              {tutor.schoolLevel}
            </h3>
            </div>}
            <h3 className=" text-sm">Verifizierte Fächer:</h3>
            <h3 className=" text-xs -mt-1 -mb-3 font-semibold">
              {tutor.verified.length > 0? tutor.verified.join(", ") : "keine"}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

{
  /* 
        <div className="avatar">
  <div className="w-24 mask mask-squircle">
    <img src={"https://avatars.dicebear.com/api/pixel-art-neutral/" + tutor.id + ".svg"} />
  </div>
</div>
        */
}
