import React from 'react'
import { Navbar } from '../components/navbar/Navbar'


export const PageNotFound = () => {
  return (
    <div className='flex flex-col h-screen'>
      <Navbar></Navbar>
      <div className='flex-grow flex flex-col justify-center items-center'>
      <h1 className='text-7xl text-primary font-bold'>404</h1>
      <h2 className='text-lg mt-2'>Die Seite konnte nicht gefunden werden.</h2>
      </div>
    </div>
  )
}
