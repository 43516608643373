import { addDoc, collection, serverTimestamp, where } from "firebase/firestore";
import React, { useState } from "react";
import { firestore } from "../../config/firebase";
import { useAuth } from "../../context/AuthContext";

import { Store } from "react-notifications-component";

export const NewOffer = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(20);
  const [subject, setSubject] = useState("Mathematik");
  const [place, setPlace] = useState("Aargau");
  const [online, setOnline] = useState(false);
  const { user } = useAuth();

  const clearFields = () => {
    setTitle("");
    setDescription("");
    setPrice(20);
    setPlace("Aargau");
    setSubject("Mathematik");
    setOnline(false);
  };

  const addOffer = async (e) => {
    e.preventDefault();

    try {
      await addDoc(collection(firestore, "offers"), {
        title: title,
        description: description,
        price: price,
        subject: subject,
        place: place,
        online: Boolean(online),
        tutorId: user.uid,
        date: serverTimestamp(),
      });

      Store.addNotification({
        title: "Angebot erstellt",
        message: "Das Angebot " + title + " wurder erfolgreich erstellt.",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      })
      
      clearFields()
      document.getElementById('offer-modal').checked = false;

    } catch (error) {
      Store.addNotification({
        title: "fehler",
        message: error,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      

    }
  };

  return (
    <div className="w-full">
      <label
        htmlFor="offer-modal"
        className="border-2 border-primary bg-purple-50 rounded-lg cursor-pointer h-full w-full flex flex-col justify-center items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-16 h-16"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 4.5v15m7.5-7.5h-15"
          />
        </svg>
        <h3 className="text-lg font-semibold">Neues Angebot</h3>
      </label>
      <div>
        <input type="checkbox" id="offer-modal" className="modal-toggle" />
        <div className="modal">
          <div className="modal-box relative h-auto">
            <label
              htmlFor="offer-modal"
              className="btn btn-sm btn-circle absolute right-2 top-2"
              onClick={clearFields}
            >
              ✕
            </label>
            <h3 className="text-lg font-bold">Erstelle ein neues Angebot</h3>
            <form onSubmit={addOffer}>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">Titel</span>
                </label>
                <input
                  type="text"
                  placeholder="Neues Angebot"
                  maxLength={25}
                  className="input input-bordered w-full "
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Beschreibung</span>
                </label>
                <textarea
                  className="textarea textarea-bordered h-24"
                  placeholder="Wieso sollte man dein Angebot nehmen?"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                ></textarea>
              </div>
              <div className="flex gap-2 justify-center items-end ">
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">Preis</span>
                  </label>
                  <input
                    type="number"
                    placeholder="20"
                    min="0"
                    max="100"
                    className="input input-bordered w-full"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    required
                  />
                </div>
                <div className="form-control w-1/2 md:w-full">
                  <label className="label">
                    <span className="label-text">Fach</span>
                  </label>
                  <select
                    className="select select-bordered"
                    required
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  >
                    <option>Mathematik</option>
                    <option>Informatik</option>
                    <option>Finanz und Rechnungswesen</option>
                    <option>Chemie</option>
                    <option>Sprachen</option>
                    <option>Wirtschaft</option>
                    <option>Physik</option>
                    <option>Biologie</option>
                    <option>Andere</option>
                  </select>
                </div>
              </div>

              <div className="form-control w-full mt-2">
                <label className="label">
                  <span className="label-text">
                    In welchem Kanton möchtest du Nachhilfe anbieten?
                  </span>
                </label>
                <select
                  className="select select-bordered"
                  required
                  value={place}
                  onChange={(e) => setPlace(e.target.value)}
                >
                  <option>Aargau</option>
                  <option>Appenzell Ausserrhoden</option>
                  <option>Appenzell Innerrhoden</option>
                  <option>Basel-Landschaft</option>
                  <option>Basel-Stadt</option>
                  <option>Bern</option>
                  <option>Freiburg</option>
                  <option>Genf</option>
                  <option>Glarus</option>
                  <option>Graubünden</option>
                  <option>Jura</option>
                  <option>Luzern</option>
                  <option>Neuenburg</option>
                  <option>Nidwalden</option>
                  <option>Obwalden</option>
                  <option>Schaffhausen</option>
                  <option>Schwyz</option>
                  <option>Solothurn</option>
                  <option>St. Gallen</option>
                  <option>Thurgau</option>
                  <option>Tessin</option>
                  <option>Uri</option>
                  <option>Wallis</option>
                  <option>Waadt</option>
                  <option>Zug</option>
                  <option>Zürich</option>
                </select>
              </div>
              <div className="form-control">
                <label className="label cursor-pointer">
                  <span className="label-text">Nachhilfe Online anbieten?</span>
                  <input
                    type="checkbox"
                    className="toggle toggle-primary"
                    checked={online}
                    onChange={(e) => setOnline(e.target.checked)}
                  />
                </label>
              </div>
              <div className="flex justify-end w-full">
                <button
                  className="btn mt-5 btn-primary"
                  htmlFor="offer-modal"
                  type="submit"
                >
                  Angebot aufschalten
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
