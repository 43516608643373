import { async } from "@firebase/util";
import { deleteDoc, doc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { firestore } from "../../config/firebase";
import { useDatabase } from "../../context/DatabaseContext";
import { useInnerDatabase } from "../../context/InnerDatabaseContext";

export const Request = ({ data }) => {
  const { offers, publicUsers} = useDatabase();
  const [offer, setOffer] = useState();
  const [tutor, setTutor] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, [data]);

  const getData = async () => {
    const o = await offers.find((o) => o.id === data.offerId);
    setOffer(o);
    const t = await publicUsers.find((u) => u.id === o.tutorId);
    setTutor(t);
  };

  const deleteRequest = async () => {
    await deleteDoc(doc(firestore, "requests", data.id));
  };

  let status = "ausstehend";

  if (data.status === "accepted") {
    status = "angenommen";
  } else if (data.status === "denied") {
    status = "abgelehnt";
  }

  

  return (
    <div className="card mr-2">
      <div
        className={
          data.status === "pending"
            ? "bg-sky-50 border-2 border-info p-3 rounded-lg flex justify-between h-24 "
            : data.status === "accepted"
            ? "bg-green-50 border-2 border-success p-3 rounded-lg flex justify-between h-24"
            : "bg-red-50 border-2 border-error p-3 rounded-lg flex justify-between h-24"
        }
      >
        <div>
          <div className="md:flex md:items-center md: gap-1 w-48 md:w-72 ">
            <h1
              className="font-semibold text-lg cursor-pointer truncate"
              onClick={() => navigate("/offers/" + offer?.id)}
            >
              {offer?.title}{" "}
            </h1>
            <h2 className="text-sm font-semibold text-gray-500 hidden md:block">
              ({status})
            </h2>
          </div>

          <h2 className="text-sm font-semibold text-gray-500">
            an {tutor?.firstname + " " + tutor?.surname}
          </h2>
          <h2 className="text-sm font-semibold text-gray-400">
            {data.date.toDate().toLocaleDateString("en-GB")}
          </h2>
        </div>
        <div className="flex items-center gap-2">
          {data.status === "accepted" ? (
            <div className="tooltip tooltip-left" data-tip="kontaktieren">
            <a
              className="btn btn-square btn-info btn-outline"
              href={"mailto:" + data?.email + "?subject=ScholarLink Anfrage"}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                />
              </svg>
            </a>
          </div>
          ) : (
            <div className="tooltip tooltip-left" data-tip="löschen">
            <button
              className="btn btn-square btn-error btn-outline"
              onClick={deleteRequest}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 "
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
            </button>
          </div>
            
          )}
        </div>
      </div>
    </div>
  );
};
