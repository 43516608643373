import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate} from "react-router-dom";
import { firestore } from "../../config/firebase";
import { useAuth } from "../../context/AuthContext";
import { useDatabase } from "../../context/DatabaseContext";

import { TutorCard } from "./TutorCard";
import { useInnerDatabase } from "../../context/InnerDatabaseContext";
import { RequestButton } from "./RequestButton";

export const OfferDescription = ({ id, setShowDescription, result }) => {
  const navigate = useNavigate();

  const { offers, publicUsers } = useDatabase();
  const [tutor, setTutor] = useState();
  const [offer, setOffer] = useState();


  const { user } = useAuth();


  useEffect(() => {
    getData();
    
  });

  useEffect(() => {
    getData();
  }, [id, offers]);

  const getData = async () => {
    try {
      const o = await offers.find((o) => o.id === id);
      setOffer(o);
      const s = await publicUsers.find((u) => u.id === o.tutorId);
      setTutor(s);
    } catch (error) {
      console.log(error);
    }
  };

  


  

  if (offer) {
    return (
      <div className="md:pl-5 py-5">
        <button
          className="btn btn-primary btn-sm gap-2 mb-3 md:hidden"
          onClick={() => setShowDescription(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5"
            />
          </svg>
          zurück
        </button>

        <div className="flex flex-col-reverse md:flex-row-reverse justify-between ">
          <div className="flex flex-col gap-2">
            {tutor && <TutorCard tutor={tutor}></TutorCard>}
            <div className="stats md:bg-white bg-slate-300 md:w-56">
              <div className="stat">
                <div className="stat-title">Dieser Tutor verlangt</div>
                <div className="stat-value text-primary">{offer.price}CHF</div>
                <div className="stat-desc">pro Stunde</div>
              </div>
            </div>
            {user ? (
              <RequestButton offer={offer}></RequestButton>
            ) : (
              <div className="flex flex-col gap-2 md:w-56">
                <h2 className="font-semibold text-center">
                  Melde dich an, um Anfragen zu senden.
                </h2>
                <button
                  className="btn btn-primary"
                  onClick={() => navigate("/login")}
                >
                  Anmelden
                </button>
              </div>
            )}
          </div>

          <div className="flex grow flex-col">
            <div className="flex gap-2 justify-start items-center">
              <h1 className="text-3xl font-bold">{offer.title} </h1>
              {false && (
                <div className="badge badge-md badge-primary hidden md:block">
                  {offer.subject}
                </div>
              )}
            </div>
            <div className="mt-5">
              <h2 className="font-semibold">Ort:</h2>
              <p>
                {offer.place}
                {offer.online && ", Online"}
              </p>
            </div>
            <div className="mt-5">
              <h2 className="font-semibold">Datum:</h2>
              <p>{offer.date.toDate().toLocaleDateString("en-GB")}</p>
            </div>
            <div className="mt-5">
              <h2 className="font-semibold">Fach:</h2>
              <p>{offer.subject}</p>
            </div>
            <div className="mt-5">
              <h2 className="font-semibold">Beschreibung:</h2>
              <p>{offer.description}</p>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex justify-center items-center h-full">
        <h1 className="text-2xl text-primary font-semibold text-center w-1/2">
          Wir haben leider kein passendes Angebot gefunden.
        </h1>
      </div>
    );
  }
};
