import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Navbar } from "../components/navbar/Navbar";
import { useAuth } from "../context/AuthContext";

export const LogIn = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, seterrorMessage] = useState("")

  const [resetEmail, setResetEmail] = useState("");

  const { signIn, resetPassword } = useAuth();

  const login = (e) => {
    e.preventDefault();
    signIn(email, password).then(() => {
      // Signed in 
      navigate("/")
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      seterrorMessage(error.message)
    });
  };

  return (
    <div className="h-screen flex flex-col">
      <Navbar></Navbar>
      <div className=" p-5 flex-grow flex justify-center items-center">
        <div className="flex justify-center items-center w-full md:w-2/3 lg:w-1/2">
          <form
            className="flex flex-col justify-center gap-2 md:w-5/6 xl:w-2/3"
            onSubmit={login}
          >
            <h1 className="text-3xl font-bold mb-4">Anmelden</h1>
            <p className="mb-2">
              Du hast noch keinen einen Account?{" "}
              <span
                className="link link-primary"
                onClick={() => navigate("/createAccount")}
              >
                Registrieren
              </span>
            </p>

            <p className="mb-10">
              Passwort vergessen?{" "}
              <label
                htmlFor="reset-password-modal"
                className="link link-primary"
              >
                Klicke Hier
              </label>
            </p>

            <div className="form-control w-full">
              <label className="label">
                <span className="label-text font-semibold">Email</span>
              </label>
              <input
                type="email"
                placeholder="max@mustermann.ch"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="input input-bordered w-full "
                required
              />
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text font-semibold">Passwort</span>
              </label>
              <input
                type="password"
                placeholder="••••••••"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="input input-bordered w-full"
                required
              />
            </div>
            <h2 className="text-error font-semibold">{errorMessage}</h2>
            <h3 className="font-semibold mt-2">
              Mit dem Anmelden stimme ich den{" "}
              <a className="link link-primary" onClick={() => navigate("/agb")}>
                AGBs
              </a>{" "}
              und der{" "}
              <a
                className="link link-primary"
                onClick={() => navigate("/datenschutz")}
              >
                Datenschutzerklährung
              </a>{" "}
              von ScholarLink zu.
            </h3>

            <div className="flex justify-end">
              <button className="btn btn-primary w-1/3 mt-4" type="submit">
                Anmelden
              </button>
            </div>
          </form>
        </div>
        <input
          type="checkbox"
          id="reset-password-modal"
          className="modal-toggle"
        />
        <div className="modal">
          <div className="modal-box relative">
            <label
              htmlFor="reset-password-modal"
              className="btn btn-sm btn-circle absolute right-2 top-2"
              onClick={() => setResetEmail("")}
            >
              ✕
            </label>
            <h3 className="text-lg font-bold">Passwort zurücksetzten</h3>
            <form onSubmit={() => resetPassword(resetEmail)}>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">Email</span>
                </label>
                <input
                  type="email"
                  placeholder="max@mustermann.ch"
                  className="input input-bordered w-full"
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.target.value)}
                  required
                />
              </div>
              <div className="flex justify-end w-full">
                <button className="btn mt-5 btn-primary" type="submit">
                  Email senden
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
