import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDatabase } from "../../context/DatabaseContext";

export const Offer = ({ data, setShowDescription }) => {
  const navigate = useNavigate();
  let { id } = useParams();

  const { publicUsers } = useDatabase()
  const [tutorData, setTutorData] = useState();

  useEffect(() => {
    getTutorData();
  }, [data]);

  const getTutorData = async () => {
    const s = await publicUsers.find((u) => u.id === data.tutorId);
    setTutorData(s);
  };

  

  return (
    <div
      className="py-2 group"
      onClick={() => {
        navigate("/offers/" + data.id);
        setShowDescription(true);
      }}
    >
      <div className={tutorData?.verified.includes(data.subject) ? "indicator w-full transition-all duration-200 transform hover:shadow-sm hover:-translate-y-0.5 rounded-lg" : "transition-all duration-200 transform hover:shadow-sm hover:-translate-y-1 rounded-lg"}>
        {tutorData?.verified.includes(data.subject) && (
          <span className="indicator-item bg-green-500 rounded-full h-7 w-7 md:group-hover:w-24 transition-all duration-150 flex justify-center items-center px-1 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-6 h-6 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
              />
            </svg>

            <h1 className="text-sm font-semibold text-white md:group-hover:block hidden transition-all duration-200 ease-in">
              Verifiziert
            </h1>
          </span>
        )}

        <div
          className={
            id === data.id
              ? tutorData?.verified.includes(data.subject)
                ? "bg-green-50 border-2 border-green-500 p-3 rounded-lg flex justify-between h-24 md:h-28 xl:h-28 w-full cursor-pointer"
                : "md:bg-purple-50 border-2 md:border-primary p-3 rounded-lg flex justify-between h-24 md:h-28 xl:h-28 w-full cursor-pointer"
              : tutorData?.verified.includes(data.subject)
              ? "bg-green-50 border-2 border-green-500 p-3 rounded-lg flex justify-between h-24 md:h-28 xl:h-28 w-full cursor-pointer"
              : "bg-white border-2 p-3 rounded-lg flex justify-between h-24 md:h-28 xl:h-28 w-full cursor-pointer"
          }
        >
          <div className="flex flex-col justify-between">
            <div>
              <h1 className="font-semibold text-xl">{data.title}</h1>
              <h5 className="text-xs font-semibold text-gray-500">
                ({data.subject})
              </h5>
              
            </div>
            {false && (
              <h2 className=" font-semibold">
                von {tutorData?.firstname + " " + tutorData?.surname}
              </h2>
            )}
          </div>
          <div className="flex flex-col justify-between items-end">
            <div className="md:hidden lg:block">
              <div className="flex gap-1 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4 "
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                  />
                </svg>

                <h2 className="text-sm font-semibold text-gray-500 truncate">
                  {data.place}
                  {data.online && ", Online"}
                </h2>
              </div>
            </div>

            <h1 className="font-bold text-xl">{data.price}CHF</h1>
          </div>
        </div>
      </div>
    </div>
  );
};
