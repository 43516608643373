import React from "react";
import { Navbar } from "../components/navbar/Navbar";

export const Licenses = () => {
  return (
    <div>
      <Navbar></Navbar>
      <main className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <h1 className="text-lg font-bold">Lizenzen</h1>
        <div>
          <br />
          Das Bild "Croodles - Doodle your face" wurde von Vijay Verma unter der
          Attribution 4.0 International (CC BY 4.0) lizenziert.
          <br />
          <a href="https://www.figma.com/community/file/966199982810283152">
            Link zum Inhaber
          </a>
        </div>
      </main>
    </div>
  );
};
