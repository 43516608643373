import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Navbar } from "../components/navbar/Navbar";
import { auth, firestore } from "../config/firebase";
import { Store } from "react-notifications-component";

export const CreateAccount = () => {
  const navigate = useNavigate();

  const [firstname, setFirstname] = useState("");
  const [surname, setSurname] = useState("");
  const [birtdate, setBirtdate] = useState("");
  const [schoolLevel, setSchoolLevel] = useState("Gymnasium");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [AGBs, setAGBs] = useState(false);
  const [tutor, setTutor] = useState(false);

  const [errorMessage, seterrorMessage] = useState("");

  const createAccount = (e) => {
    e.preventDefault();

    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Signed in
        const user = userCredential.user;
        const timestamp = new Date(birtdate);
        await setDoc(doc(firestore, "users", user.uid), {
          firstname: firstname,
          surname: surname,
          birtdate: timestamp,
          schoolLevel: schoolLevel,
          tutor: tutor,
          verified: [],
          creationDate: serverTimestamp(),
        });

        await setDoc(doc(firestore, "publicUsers", user.uid), {
          firstname: firstname,
          surname: surname,
          birtdate: timestamp,
          schoolLevel: schoolLevel,
          tutor: tutor,
          verified: [],
          creationDate: serverTimestamp(),
        });

        console.log(user.uid)

        fetch(
          "https://scholarlink-backend.onrender.com/sendWelcomeEmail?code=c606be38-d5ca-4505-acb0-cc9ba0d37245-357eef1f-024e-43b5-95cc-3845fba86c4c&email=" + email + "&firstname=" + firstname
        ).then(res => {
          console.log(res)
          if (res.ok) {
            Store.addNotification({
              title: "Account erstellt",
              message: "Dein Account wurde erfolgreich erstellt.",
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 3000,
                onScreen: true,
              },
            })
          } else {
              throw new Error("Error: " + res.status);
          }
        })

        navigate("/");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
        seterrorMessage(error.message);
      });
  };

  return (
    <div className="h-screen flex flex-col">
      <Navbar></Navbar>
      <div className="flex-grow px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 w-full flex">
        <div className="hidden lg:block w-1/3 card bg-gradient-to-br from-sky-400 to-lime-300 text-primary-content my-5">
          {tutor ? (
            <div className="card-body">
              <h2 className="card-title text-3xl">Werde Tutor</h2>
              <p>
                Als Tutor hast du wie auch als Student die Möglichkeit Nachhilfe
                zu nehmen, aber du kannst zusätzlich auch Nachhilfe anbieten.
              </p>
              <div className="card-actions justify-end"></div>
            </div>
          ) : (
            <div className="card-body">
              <h2 className="card-title text-3xl">Werde Mitglied</h2>
              <p>
                Profitiere als Mitglied von einer wachsenden Community und werde
                in jedem Fach zum Profi.
              </p>
              <div className="card-actions justify-end"></div>
            </div>
          )}
        </div>

        <div className="flex justify-center items-center w-full lg:w-2/3">
          <form
            className="flex flex-col justify-center gap-2 w-4/5"
            onSubmit={createAccount}
          >
            <h1 className="text-3xl font-bold mb-4">Account erstellen</h1>
            <p className="mb-10">
              Du hast bereits einen Account?{" "}
              <span
                className="link link-primary"
                onClick={() => navigate("/login")}
              >
                Anmelden
              </span>
            </p>
            <div className="mb-5">
              <label className="label">
                <span className="label-text font-semibold">
                  Welche Rolle spricht dich an?
                </span>
              </label>
              <div className="flex gap-5 w-full flex-col md:flex-row">
                <div
                  className={
                    tutor
                      ? "flex justify-start items-center py-5 border-2 rounded-lg w-full cursor-pointer"
                      : "flex justify-start items-center py-5 border-2 rounded-lg w-full cursor-pointer border-primary bg-violet-50"
                  }
                  onClick={() => setTutor(false)}
                >
                  <input
                    type="radio"
                    name="radio-2"
                    className="radio checked:radio-primary w-5 h-5 mx-4 peer"
                    checked={!tutor}
                  />
                  <h3 className="peer-checked:text-primary font-semibold">
                    Student
                  </h3>
                </div>

                <div
                  className={
                    !tutor
                      ? "flex justify-start items-center py-5 border-2 rounded-lg w-full cursor-pointer"
                      : "flex justify-start items-center py-5 border-2 rounded-lg w-full cursor-pointer border-primary bg-violet-50"
                  }
                  onClick={() => setTutor(true)}
                >
                  <input
                    type="radio"
                    name="radio-2"
                    className="radio checked:radio-primary w-5 h-5 mx-4 peer"
                    checked={tutor}
                  />
                  <h3 className="peer-checked:text-primary font-semibold">
                    Tutor
                  </h3>
                </div>
              </div>
            </div>

            <div className="flex gap-2">
              <div className="form-control w-full ">
                <label className="label">
                  <span className="label-text font-semibold">Vorname</span>
                </label>
                <input
                  type="text"
                  placeholder="Max"
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                  className="input input-bordered w-full "
                  required
                />
              </div>
              <div className="form-control w-full ">
                <label className="label">
                  <span className="label-text font-semibold">Name</span>
                </label>
                <input
                  type="text"
                  placeholder="Mustermann"
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                  className="input input-bordered w-full"
                  required
                />
              </div>
            </div>
            <div className="flex gap-2">
              <div className="form-control w-full ">
                <label className="label">
                  <span className="label-text font-semibold">Geburtsdatum</span>
                </label>
                <input
                  type="date"
                  placeholder="dd/mm/yyyy"
                  value={birtdate}
                  onChange={(e) => setBirtdate(e.target.value)}
                  className="input input-bordered w-full "
                  required
                />
              </div>
              <div className="form-control w-1/2 md:w-full">
                <label className="label">
                  <span className="label-text font-semibold">Schulstufe</span>
                </label>
                <select
                  className="select select-bordered"
                  required
                  value={schoolLevel}
                  onChange={(e) => setSchoolLevel(e.target.value)}
                >
                  <option>Primarschule</option>
                  <option>Sekundarstufe</option>
                  <option>Gymnasium</option>
                  <option>Berufslehre</option>
                  <option>Uni/FH</option>
                  <option>Ausgebildet</option>
                </select>
              </div>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text font-semibold">Email</span>
              </label>
              <input
                type="email"
                placeholder="max@mustermann.ch"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="input input-bordered w-full "
                required
              />

              <label className="label">
                <span className="label-text-alt">
                  Hinweis: Diese Email wird für die Kommunikation verwendet.
                </span>
              </label>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text font-semibold">Passwort</span>
              </label>
              <input
                type="password"
                placeholder="••••••••"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="input input-bordered w-full"
                required
              />
            </div>
            <h2 className="text-error font-semibold">{errorMessage}</h2>
            <div
              className="flex gap-2 mt-4"
              onChange={() => setAGBs((old) => !old)}
            >
              <input
                type="checkbox"
                checked={AGBs}
                className="checkbox checkbox-primary"
                required
              />
              <h3 className="font-semibold">
                Ich stimme den{" "}
                <a
                  className="link link-primary"
                  onClick={() => navigate("/agb")}
                >
                  AGBs
                </a>{" "}
                und der{" "}
                <a
                  className="link link-primary"
                  onClick={() => navigate("/datenschutz")}
                >
                  Datenschutzerklärung
                </a>{" "}
                von ScholarLink zu.
              </h3>
            </div>
            <div className="flex justify-end">
              <button className="btn btn-primary w-1/3 mt-4" type="submit">
                Account erstellen
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
