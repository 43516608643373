import React from "react";
import { useNavigate } from "react-router-dom";
import { Footer } from "../components/footer/Footer";
import { Navbar } from "../components/navbar/Navbar";
import { useDatabase } from "../context/DatabaseContext";
import Link from "react-scroll/modules/components/Link";
import { SubjectSection } from "../components/home/SubjectSection";

export const Home = () => {
  const navigate = useNavigate();
  const { publicUsers, offers } = useDatabase();
  

  

  return (
    <div>
      <main className="bg-gray-50">
        <div className="relative h-screen" id="start">
          <div className="absolute bottom-0 right-0 overflow-hidden lg:inset-y-0">
            <img
              className="w-auto h-full"
              src="https://d33wubrfki0l68.cloudfront.net/1e0fc04f38f5896d10ff66824a62e466839567f8/699b5/images/hero/3/background-pattern.png"
              alt=""
            />
          </div>

          <Navbar></Navbar>
          <section className="relative py-12 sm:py-16 lg:pt-20 xl:pb-0">
            <div className="relative px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
              <div className="max-w-3xl mx-auto text-center">
                <p className="inline-flex px-4 py-2 text-base text-gray-900 border border-gray-200 rounded-full font-pj">
                  Von Schülern, für Schüler.
                </p>
                <h1 className="mt-5 text-4xl font-bold leading-tight text-gray-900 sm:text-5xl sm:leading-tight lg:text-6xl lg:leading-tight font-pj">
                  Einfach die passende Nachhilfe finden.
                </h1>
                <p className="max-w-md mx-auto mt-6 text-base leading-7 text-gray-600 font-inter">
                  ScholarLink ist immer noch in der Beta. Hilf uns diese
                  Plattform besser zu machen.{" "}
                  <a
                    className="link link-primary"
                    href="mailto:elia.ritzmann@stud.altekanti.ch?subject=Feedback%20ScholarLink"
                  >
                    Feedback geben
                  </a>
                </p>

                <div className="flex flex-col justify-center items-center gap-2">
                  <Link
                    className="relative inline-flex mt-10 group"
                    to="chooser"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={1250}
                  >
                    <div className="absolute transitiona-all duration-1000 opacity-70 -inset-px bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt"></div>

                    <p
                      className="relative inline-flex items-center justify-center px-8 py-4 text-lg font-bold text-white transition-all duration-200 bg-gray-900 font-pj rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                      role="button"
                    >
                      Zu den Angeboten
                    </p>
                  </Link>
                  {false && (
                    <button
                      className="inline-flex items-center px-4 py-4 mt-4 text-lg font-bold transition-all duration-200 bg-transparent border border-transparent sm:mt-0 font-pj justif-center rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 hover:bg-gray-200 focus:bg-gray-200"
                      role="button"
                      onClick={() => navigate("/createAccount")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2.5}
                        stroke="currentColor"
                        className="w-5 h-5 mr-2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"
                        />
                      </svg>
                      Tutor werden
                    </button>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>

        <SubjectSection></SubjectSection>
        <section className="relative bg-gray-900 mt-24">
          <div className="absolute inset-0">
            <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50"></div>
          </div>

          <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-2">
            <div className="flex items-end px-4 pb-16 bg-white pt-28 sm:px-6 lg:px-8 lg:pb-24 xl:pr-12">
              <div className="max-w-lg mx-auto lg:mx-0">
                <p className="text-5xl sm:text-6xl lg:text-7xl">🚀</p>
                <h1 className="mt-10 text-3xl font-bold text-gray-900 sm:text-4xl lg:text-5xl">
                  Zeige ihnen, dass du es kannst.
                </h1>
                <p className="mt-6 text-base font-normal leading-7 text-gray-500">
                  Lasse deinen Account verifizieren und baue das Vertrauen zu
                  deinen Schülern auf.
                </p>
                <div className="relative inline-flex mt-10 group">
                  <div className="absolute transitiona-all duration-1000 opacity-70 inset-0 bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-xl blur-lg filter group-hover:opacity-100 group-hover:duration-200"></div>

                  <a
                    title=""
                    className="inline-flex relative items-center justify-center w-full sm:w-auto px-8 py-3 sm:text-sm text-base sm:py-3.5 font-semibold text-white transition-all duration-200 bg-gray-900 border border-transparent rounded-lg hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                    role="button"
                    onClick={() => navigate("/faq")}
                  >
                    Meinen Account verifizieren
                  </a>
                  
                </div>
                <button className="btn btn-ghost ml-5" onClick={() => navigate("/faq")}>mehr erfahren</button>
              </div>
              
            </div>

            <div className="relative flex items-end px-4 py-16 bg-gray-900 sm:px-6 lg:pb-24 lg:px-8 xl:pl-12">
              <div className="absolute inset-0">
                <img
                  className="object-cover w-full h-full"
                  src="https://landingfoliocom.imgix.net/store/collection/clarity-blog/images/hero/6/grid-pattern.svg"
                  alt=""
                ></img>
              </div>

              <div className="relative w-full max-w-lg mx-auto lg:max-w-none">
                <p className="text-lg font-bold text-white">
                  Hebe dich von deiner Konkurenz ab
                </p>

                <div className="mt-6 space-y-5">
                  <div className="py-2 transition-all duration-200 transform hover:shadow-lg hover:-translate-y-1 group">
                    <div className="indicator w-full">
                      <span className="indicator-item bg-green-500 rounded-full h-7 w-7 group-hover:w-24 transition-all duration-150 flex justify-center items-center px-1 ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={2}
                          stroke="currentColor"
                          className="w-6 h-6 text-white"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                          />
                        </svg>

                        <h1 className="text-sm font-semibold text-white group-hover:block hidden transition-all duration-200 ease-in">
                          Verifiziert
                        </h1>
                      </span>
                      <div className="bg-white md:bg-green-50 border-4 md:border-green-500 p-3 rounded-lg flex justify-between h-24 md:h-full xl:h-28 w-full cursor-pointer">
                        <div className="flex flex-col justify-between">
                          <div>
                            <h1 className="font-semibold text-xl">
                              Dein Angebot
                            </h1>
                            <h5 className="text-xs font-semibold text-gray-500">
                              (Wirtschaft)
                            </h5>
                          </div>

                          <h2 className=" font-semibold">
                            von dir
                          </h2>
                        </div>
                        <div className="flex flex-col justify-between items-end">
                          <div className="md:hidden lg:block">
                            <div className="flex gap-1 items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-4 h-4 "
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                                />
                              </svg>

                              <h2 className="text-sm font-semibold text-gray-500 truncate">
                                Aargau, Online
                              </h2>
                            </div>
                          </div>

                          <h1 className="font-bold text-xl">30CHF</h1>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="py-2 transition-all duration-200 transform hover:shadow-lg hover:-translate-y-1">
                    <div className="bg-white  border-2 p-3 rounded-lg flex justify-between h-24 md:h-full xl:h-28 cursor-pointer">
                      <div className="flex flex-col justify-between">
                        <div>
                          <h1 className="font-semibold text-xl">
                            Normales Angebot
                          </h1>
                          <h5 className="text-xs font-semibold text-gray-500">
                            (Andere)
                          </h5>
                        </div>

                        <h2 className=" font-semibold">von der Konkurenz</h2>
                      </div>
                      <div className="flex flex-col justify-between items-end">
                        <div className="md:hidden lg:block">
                          <div className="flex gap-1 items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-4 h-4 "
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                              />
                            </svg>

                            <h2 className="text-sm font-semibold text-gray-500 truncate">
                              Aargau, Online
                            </h2>
                          </div>
                        </div>

                        <h1 className="font-bold text-xl">20CHF</h1>
                      </div>
                    </div>
                  </div>

                  <div className="py-2 transition-all duration-200 transform hover:shadow-lg hover:-translate-y-1">
                    <div className="bg-white  border-2 p-3 rounded-lg flex justify-between h-24 md:h-full xl:h-28 cursor-pointer">
                      <div className="flex flex-col justify-between">
                        <div>
                          <h1 className="font-semibold text-xl">
                            Normales Angebot
                          </h1>
                          <h5 className="text-xs font-semibold text-gray-500">
                            (Andere)
                          </h5>
                        </div>

                        <h2 className=" font-semibold">von der Konkurenz</h2>
                      </div>
                      <div className="flex flex-col justify-between items-end">
                        <div className="md:hidden lg:block">
                          <div className="flex gap-1 items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-4 h-4 "
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                              />
                            </svg>

                            <h2 className="text-sm font-semibold text-gray-500 truncate">
                              Aargau, Online
                            </h2>
                          </div>
                        </div>

                        <h1 className="font-bold text-xl">20CHF</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="py-10 bg-gray-50 sm:py-16 lg:py-24">
          <div className="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
            <div className="max-w-2xl mx-auto text-center">
              <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
                ScholarLink in Zahlen
              </h2>
              <p className="mt-3 text-xl leading-relaxed text-gray-600 md:mt-8">
                Veröffentlicht im Januar 2023, hat usere Plattform bereits
                diesen Umfang erreicht.
              </p>
            </div>

            <div className="grid grid-cols-1 gap-8 mt-10 text-center lg:mt-24 sm:gap-x-8 md:grid-cols-3">
              <div>
                <h3 className="font-bold text-7xl">
                  <span className="text-transparent bg-clip-text bg-gradient-to-r from-fuchsia-600 to-blue-600">
                    {" "}
                    {publicUsers.length}+{" "}
                  </span>
                </h3>
                <p className="mt-4 text-xl font-medium text-gray-900">
                  Mitglieder
                </p>
                <p className="text-base mt-0.5 text-gray-500"></p>
              </div>

              <div>
                <h3 className="font-bold text-7xl">
                  <span className="text-transparent bg-clip-text bg-gradient-to-r from-fuchsia-600 to-blue-600">
                    {" "}
                    {20}+{" "}
                  </span>
                </h3>
                <p className="mt-4 text-xl font-medium text-gray-900">
                  Nachhilfe Anfragen
                </p>
                <p className="text-base mt-0.5 text-gray-500"></p>
              </div>

              <div>
                <h3 className="font-bold text-7xl">
                  <span className="text-transparent bg-clip-text bg-gradient-to-r from-fuchsia-600 to-blue-600">
                    {" "}
                    {offers.length}+{" "}
                  </span>
                </h3>
                <p className="mt-4 text-xl font-medium text-gray-900">
                  Angebote
                </p>
                <p className="text-base mt-0.5 text-gray-500"></p>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
};
