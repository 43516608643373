import { doc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { firestore } from "../../config/firebase";
import { useDatabase } from "../../context/DatabaseContext";
import { useInnerDatabase } from "../../context/InnerDatabaseContext";
import { Store } from "react-notifications-component";

export const TutorRequest = ({ data }) => {
  const { offers } = useDatabase();
  const { publicUsers } = useDatabase();
  const { userData } = useInnerDatabase();
  const [offer, setOffer] = useState();
  const [student, setStudent] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, [data]);

  const getData = async () => {
    const o = await offers.find((o) => o.id === data.offerId);
    setOffer(o);
    const s = await publicUsers.find((u) => u.id === data.uid);
    setStudent(s);
  };

  const decideRequest = async (accept) => {
    if (accept) {
      await updateDoc(doc(firestore, "requests", data.id), {
        status: "accepted",
        email: userData.email,
      });

      fetch(
        "https://scholarlink-backend.onrender.com/sendEmailRequestAccepted?code=c606be38-d5ca-4505-acb0-cc9ba0d37245-357eef1f-024e-43b5-95cc-3845fba86c4c&requestId=" +
          data.id
      ).then((res) => {
        console.log(res);
        if (res.ok) {
          Store.addNotification({
            title: "Anfrage angenommen",
            message: "Dem Schüler wurde die gute Nachricht übermittelt.",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true,
            },
          });
        } else {
          throw new Error("Error: " + res.status);
        }
      });
    } else {
      await updateDoc(doc(firestore, "requests", data.id), {
        status: "denied",
      });
    }
  };

  return (
    <div className="card mr-2">
      <div className="bg-white border-2 p-3 rounded-lg flex justify-between h-24">
        <div className="w-32 md:w-64 ">
          <h1
            className="font-semibold text-lg cursor-pointer truncate"
            onClick={() => navigate("/offers/" + data.offerId)}
          >
            {offer?.title}
          </h1>
          <h2 className="text-sm font-semibold text-gray-500">
            von {student?.firstname + " " + student?.surname}
          </h2>
          <h2 className="text-sm font-semibold text-gray-400">
            {data.date.toDate().toLocaleDateString("en-GB")}
          </h2>
        </div>
        <div className="flex items-center gap-2">
          <div className="tooltip tooltip-left" data-tip="ablehnen">
            <button
              className="btn btn-square btn-error btn-outline"
              onClick={() => decideRequest(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div className="tooltip tooltip-left" data-tip="annehmen">
            <button
              className="btn btn-square btn-success btn-outline"
              onClick={() => decideRequest(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 12.75l6 6 9-13.5"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
