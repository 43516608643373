import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useInnerDatabase } from "../../context/InnerDatabaseContext";

export const DisplayUser = () => {
  const navigate = useNavigate();

  const { userData, tutorRequests } = useInnerDatabase()

  const [notificaations, setNotificaations] = useState(0)

  
  useEffect(() => {
    countRequests()
  }, [tutorRequests])

  const countRequests = async () =>{
    let number = 0;

    for (let index = 0; index < tutorRequests.length; index++) {
      if(tutorRequests[index].status === "pending"){
        number++;
      }
      
    }
    setNotificaations(number)
  }

  return (
    <div className="indicator mr-1">
      {notificaations > 0 && (
        <span className="indicator-item badge badge-primary">
          {notificaations}
        </span>
      )}
      <button
        className="btn gap-2 btn-ghost"
        onClick={() => navigate("/profile")}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>
        {userData.firstname + " " + userData.surname}
      </button>
    </div>
  );
};
