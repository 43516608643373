import React from "react";
import { Footer } from "../components/footer/Footer";
import { Navbar } from "../components/navbar/Navbar";


export const AGBs = () => {
  return (
    <div className="bg-gray-50">
      <Navbar></Navbar>
      <body class="font-sans leading-normal tracking-normal">
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <h1 class="text-3xl font-bold text-gray-800 mb-4">
            Allgemeine Geschäftsbedingungen (AGB) von ScholarLink
          </h1>

          <h2 class="text-2xl font-bold text-gray-800 mb-4">
            Geltungsbereich und Vermittlungsplattform
          </h2>
          <ul class="mb-6 list-disc">
            <li>
              Die Plattform ScholarLink dient ausschließlich als
              Vermittlungsplattform und übernimmt keine Haftung für die
              Leistungen der Tutoren.
            </li>
            <li>
              ScholarLink stellt keine Gewähr dafür, dass ein geeigneter Tutor
              für den gewünschten Fachbereich oder das gewünschte Niveau
              verfügbar ist.
            </li>
            <li>
              ScholarLink ist berechtigt, jederzeit ohne Angabe von Gründen
              Tutoren oder Schüler von der Nutzung der Plattform auszuschließen.
            </li>
          </ul>

          <h2 class="text-2xl font-bold text-gray-800 mb-4">
            Haftung und Nutzung
          </h2>
          <ul class="mb-6 list-disc">
            <li>
              ScholarLink übernimmt keine Haftung für Verluste oder Schäden, die
              aus der Nutzung der Plattform oder der von Tutoren erbrachten
              Leistungen entstehen.
            </li>
            <li>
              Tutoren sind verpflichtet, sich an die geltenden Gesetze und
              Vorschriften zu halten und dürfen keine illegalen oder schädlichen
              Leistungen anbieten.
            </li>
            <li>
              Schüler sind verpflichtet, sich an die geltenden Gesetze und
              Vorschriften zu halten und dürfen keine illegalen oder schädlichen
              Leistungen in Anspruch nehmen.
            </li>
          </ul>
          <h2 class="text-2xl font-bold text-gray-800 mb-4">
            Nutzerpflichten und -rechte
          </h2>
          <ul class="mb-6 list-disc">
            <li>
              Tutoren sind verpflichtet, die von ihnen angebotenen Leistungen
              korrekt zu beschreiben und zu verrechnen.
            </li>
            <li>
              Schüler sind verpflichtet, die von ihnen in Anspruch genommenen
              Leistungen zeitgerecht zu bezahlen.
            </li>
            <li>
              Der Nutzer ist für seine eigenen Login-Daten verantwortlich und
              hat diese geheim zu halten. ScholarLink übernimmt keine Haftung
              für Missbrauch von Login-Daten.
            </li>
            <li>
              Nutzer sind verpflichtet, sich an die Netiquette und den
              Datenschutz zu halten.
            </li>
          </ul>
          <h2 class="text-2xl font-bold text-gray-800 mb-4">
            Änderungen an der Plattform und den AGBs
          </h2>
          <ul class="mb-6 list-disc">
            <li>
              ScholarLink ist berechtigt, jederzeit ohne Angabe von Gründen die
              Plattform oder Teile davon einzustellen oder zu ändern.
            </li>
            <li>
              ScholarLink behält sich das Recht vor, Änderungen an den AGBs
              vorzunehmen. Die Nutzer werden über solche Änderungen rechtzeitig
              in Kenntnis gesetzt.
            </li>
          </ul>

          <h2 class="text-2xl font-bold text-gray-800 mb-4">
            Haftungsbeschränkung und Garantien
          </h2>
          <ul class="mb-6 list-disc">
            <li>
              ScholarLink bietet keine Garantie für die Qualität der von Tutoren
              angebotenen Leistungen.
            </li>
            <li>
              ScholarLink übernimmt keine Haftung für Schäden, die durch den
              Missbrauch der Plattform oder der von Tutoren erbrachten
              Leistungen entstehen.
            </li>
          </ul>

          <h2 class="text-2xl font-bold text-gray-800 mb-4">
            Sprachversion und Salvatorische Klausel
          </h2>
          <ul class="mb-6 list-disc" >
            <li>
              Diese AGBs sind ausschließlich in deutscher Sprache verfügbar.
            </li>
            <li>
              Sollten einzelne Bestimmungen dieser AGBs unwirksam sein oder
              werden, bleibt die Wirksamkeit der übrigen Bestimmungen unberührt.
            </li>
          </ul>
        </div>
      </body>
      <Footer></Footer>
    </div>
  );
};
