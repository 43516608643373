import React, { useState } from 'react'

export const Subject = ({subject, icon, togglefunction}) => {
    const [active, setActive] = useState(false)
  return (
    <div className="p-4 w-full select-none h-24 md:h-full" onClick={() => {setActive((old) => !old); togglefunction(subject)}}>
                <div className="indicator w-full h-full cursor-pointer">
                    {active && <span className="indicator-item bg-primary rounded-full w-6 h-6 flex justify-center items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="w-4 h-4 text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>
                  </span>}
                  
                  <div className={active ? "flex items-center p-1 gap-1 md:gap-5 md:py-10 md:px-10 bg-violet-50 rounded-xl border-2 border-primary w-full" : "flex items-center p-1 gap-1 md:gap-5 md:py-10 md:px-10 bg-white rounded-xl border-2 y w-full"} >
                    <div className={active ? "bg-primary w-6 h-6 md:w-10 md:h-10 rounded-lg flex justify-center items-center" : "bg-gray-100 w-6 h-6 md:w-10 md:h-10 rounded-lg flex justify-center items-center"}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.3}
                        stroke="currentColor"
                        className={active ? "w-5 h-5 md:w-8 md:h-8 text-white" : "w-5 h-5 md:w-8 md:h-8 text-gray-400"}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d={icon}
                        />
                      </svg>
                    </div>
                    <h1 className={active ? "font-semibold text-sm md:text-lg truncate " : "font-semibold text-sm md:text-lg text-gray-400 tranca truncate "}>{subject}</h1>
                  </div>
                </div>
              </div>
  )
}
