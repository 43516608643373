import { createContext, useContext, useEffect, useState } from "react";

//Firestore
import { collection, query, onSnapshot, getCountFromServer } from "firebase/firestore";

import { firestore } from "../config/firebase";

//Create Context
const databaseContext = createContext();

export function useDatabase() {
  return useContext(databaseContext);
}

export const DatabaseContextProvider = ({ children }) => {
  const [offers, setOffers] = useState([]);
  const [publicUsers, setPublicUsers] = useState([]);

  //document
  const offersRef = query(collection(firestore, "offers"));

  const publicUsersRef = query(
    //collection(firestore, "publicUsers")

    //CHANGE AFTER DATABASE MIGRATION (user public data => publicUsers)
    collection(firestore, "publicUsers")
  );

  useEffect(
    () =>
      onSnapshot(offersRef, (snapshot) => {
        setOffers([]);
        setOffers(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        console.log("offers read " + new Date());
      }),
    []
  );

  useEffect(
    () =>
      onSnapshot(publicUsersRef, (snapshot) => {
        setPublicUsers([]);
        setPublicUsers(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
        console.log("publicUsers read " + new Date());
      }),
    []
  );

  const value = {
    offers,
    publicUsers,
  };

  return (
    <databaseContext.Provider value={value}>
      {children}
    </databaseContext.Provider>
  );
};
