import { Routes, Route, BrowserRouter } from "react-router-dom";
import { useAuth } from "./context/AuthContext";
import { Home } from "./pages/Home";
import { CreateAccount } from "./pages/CreateAccount";
import { LogIn } from "./pages/LogIn";
import { PageNotFound } from "./pages/PageNotFound";
import { Offers } from "./pages/Offers";
import { DatabaseContextProvider } from "./context/DatabaseContext";
import { Profile } from "./pages/Profile";
import { AGBs } from "./pages/AGBs";
import { Impressum } from "./pages/Impressum";
import { Datenschutzerklährung } from "./pages/Datenschutzerklährung";
import { Licenses } from "./pages/Licenses";
import { InnerDatabaseContextProvider } from "./context/InnerDatabaseContext";
import FAQ from "./pages/FAQ";

function App() {
  const { user } = useAuth();
  return (
    <div className="">
      <DatabaseContextProvider>
          <BrowserRouter className="h-screen flex flex-row">
            {user ? (
              <InnerDatabaseContextProvider>
              <Routes>
                <Route path="/" element={<Home></Home>}></Route>
                <Route path="/offers/:id" element={<Offers></Offers>}></Route>
                <Route path="/profile" element={<Profile></Profile>}></Route>
                <Route path="/faq" element={<FAQ></FAQ>}></Route>
                <Route
                  path="/impressum"
                  element={<Impressum></Impressum>}
                ></Route>
                <Route
                  path="/datenschutz"
                  element={<Datenschutzerklährung></Datenschutzerklährung>}
                ></Route>
                <Route path="/agb" element={<AGBs></AGBs>}></Route>
                <Route path="/licenses" element={<Licenses></Licenses>}></Route>
                <Route path="*" element={<PageNotFound></PageNotFound>}></Route>
              </Routes>
              </InnerDatabaseContextProvider>
            ) : (
              <Routes>
                <Route path="/" element={<Home></Home>}></Route>
                <Route path="/offers/:id" element={<Offers></Offers>}></Route>
                <Route path="/faq" element={<FAQ></FAQ>}></Route>
                <Route
                  path="/createAccount"
                  element={<CreateAccount></CreateAccount>}
                ></Route>
                <Route path="/login" element={<LogIn></LogIn>}></Route>
                <Route
                  path="/impressum"
                  element={<Impressum></Impressum>}
                ></Route>
                <Route
                  path="/datenschutz"
                  element={<Datenschutzerklährung></Datenschutzerklährung>}
                ></Route>
                <Route path="/agb" element={<AGBs></AGBs>}></Route>
                <Route path="/licenses" element={<Licenses></Licenses>}></Route>
                <Route path="*" element={<PageNotFound></PageNotFound>}></Route>
              </Routes>
            )}
          </BrowserRouter>
      </DatabaseContextProvider>
    </div>
  );
}

export default App;
