import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { firestore } from "../../config/firebase";
import { useInnerDatabase } from "../../context/InnerDatabaseContext";
import { ConfirmModal, triggerConformModal } from "../info/ConfirmModal";

export const YourOffers = ({ data }) => {
  const {tutorRequests} = useInnerDatabase()
  const [numberOfRequests, setnumberOfRequests] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, [data, tutorRequests]);

  const getData = async () => {
    const n = await tutorRequests.filter((r) => r.offerId === data.id);
    setnumberOfRequests(n.length);
  };

  const deleteOffer = async () => {
    for (let index = 0; index < tutorRequests.length; index++) {
      if (tutorRequests[index].offerId === data.id) {
        await updateDoc(doc(firestore, "requests", tutorRequests[index].id), {
          status: "denied",
        });
      }
    }

    await deleteDoc(doc(firestore, "offers", data.id));
  };

  return (
    <div className="border-2 w-full h-full rounded-lg p-2 flex flex-col justify-between ">
      <h1 className="text-lg font-semibold ml-2">{data.title}</h1>
      <div className="grid grid-cols-2 grid-rows-2 gap-1">
        <div className="flex flex-col justify-center items-center ">
          <h2 className="text-lg font-semibold">{data.price}CHF</h2>
          <h3 className="text-sm text-gray-400 -mt-1">Preis</h3>
        </div>
        <div className="flex flex-col justify-center items-center">
          <h2 className="text-lg font-semibold">{data.subject}</h2>
          <h3 className="text-sm text-gray-400 -mt-1">Fach</h3>
        </div>

        <div className="flex flex-col justify-center items-center">
          <h2 className="text-lg font-semibold">{numberOfRequests}</h2>
          <h3 className="text-sm text-gray-400 -mt-1">Anzahl Anfragen</h3>
        </div>
        <div className="flex flex-col justify-center items-center">
          <h2 className="text-lg font-semibold">
            {data.place}
            {data.online && ", Online"}
          </h2>
          <h3 className="text-sm text-gray-400 -mt-1">Ort</h3>
        </div>
      </div>
      <ConfirmModal
        name="deleteOfferModal"
        title="Angebot löschen?"
        text={"Möchtest du das Angebot " + data.title + " wirklich löschen?"}
        confirm={deleteOffer}
      ></ConfirmModal>
      <button
        className="btn btn-info btn-outline flex gap-2 my-1"
        onClick={() => navigate("/offers/" + data.id)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
          />
        </svg>
        Zum angebot
      </button>
      <button
        className="btn btn-error btn-outline flex gap-2"
        onClick={() => triggerConformModal("deleteOfferModal")}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 "
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
          />
        </svg>
        Löschen
      </button>
    </div>
  );
};
