import {
  deleteUser,
  EmailAuthProvider,
  reauthenticateWithCredential,
  signOut,
} from "firebase/auth";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ConfirmModal,
  triggerConformModal,
} from "../components/info/ConfirmModal";
import { Navbar } from "../components/navbar/Navbar";
import { NewOffer } from "../components/profile/NewOffer";
import { Request } from "../components/profile/Request";
import { TutorRequest } from "../components/profile/TutorRequest";
import { YourOffers } from "../components/profile/YourOffers";
import { auth, firestore } from "../config/firebase";
import { useAuth } from "../context/AuthContext";
import { useDatabase } from "../context/DatabaseContext";
import { useInnerDatabase } from "../context/InnerDatabaseContext";

export const Profile = () => {
  const { user } = useAuth();
  const { offers } = useDatabase();
  const navigate = useNavigate();

  const { userData, myRequests, tutorRequests } = useInnerDatabase();

  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const elements = [];
  const tutorElements = [];


  //sort tutor Requests by Date
  myRequests.sort((a, b) => {
    const dateA = a.date; 
    const dateB = b.date; 
    if (dateA < dateB) {
      return 1;
    }
    if (dateA > dateB) {
      return -1;
    }
  
    
    return 0;
  })

  //sort tutor Requests by Date
  tutorRequests.sort((a, b) => {
    const dateA = a.date; 
    const dateB = b.date; 
    if (dateA < dateB) {
      return 1;
    }
    if (dateA > dateB) {
      return -1;
    }
  
    
    return 0;
  })


  for (let index = 0; index < myRequests.length; index++) {
    elements.push(
      <Request data={myRequests[index]} key={myRequests[index].id}></Request>
    );
  }
  for (let index = 0; index < tutorRequests.length; index++) {
    if (
      tutorRequests[index].status === "pending"
    ) {
      tutorElements.push(
        <TutorRequest
          data={tutorRequests[index]}
          key={tutorRequests[index].id}
        ></TutorRequest>
      );
    }
  }


  const yourOffers = [];

  for (let index = 0; index < offers.length; index++) {
    if (offers[index].tutorId === user.uid) {
      yourOffers.push(
        <YourOffers
          data={offers[index]}
          key={offers[index].title + index}
        ></YourOffers>
      );
    }
  }

  const deleteAccount = async () => {
    const credential = EmailAuthProvider.credential(
      auth.currentUser.email,
      password
    );

    reauthenticateWithCredential(user, credential)
      .then(async () => {
        // User re-authenticated.
        //delete all offers
        for (let index = 0; index < offers.length; index++) {
          if (offers[index].tutorId == user.uid) {
            await deleteDoc(doc(firestore, "offers", offers[index].id));
          }
        }

        //set all Requests to denied
        for (let index = 0; index < myRequests.length; index++) {
          await updateDoc(doc(firestore, "requests", myRequests[index].id), {
            status: "denied",
          });
        }

        for (let index = 0; index < tutorRequests.length; index++) {
          await updateDoc(doc(firestore, "requests", tutorRequests[index].id), {
            status: "denied",
          });
        }

        //delete User data

        await deleteDoc(doc(firestore, "users", user.uid));
        await deleteDoc(doc(firestore, "publicUsers", user.uid));

        await deleteUser(user).catch((error) => {
          setErrorMessage(error.message);
        });

        console.log("user deleted");

        setErrorMessage("");
        navigate("/");
      })
      .catch((error) => {
        // An error ocurred
        // ...
        setErrorMessage(error.message);
      });
  };

  const logOut = async () => {
    await signOut(auth);

    navigate("/");
  };

  return (
    <div className="bg-gray-50 flex flex-col w-full overflow-hidden min-h-screen">
      <Navbar></Navbar>
      <main className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 flex flex-col lg:grid lg:grid-cols-5 lg:grid-rows-2 gap-5 w-full h-full mb-2">
        <div className="card bg-base-100 col-span-3 h-full">
          <div className="card-body">
            <h2 className="card-title">Mein Profil</h2>
            <div className="flex flex-col justify-center items-center">
              <div className="indicator">
                {userData?.verified?.length > 0 && (
                  <span className="indicator-item badge badge-primary h-6 flex gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                      />
                    </svg>
                    <h4 className="font-semibold">Verifiziert</h4>
                  </span>
                )}

                <div className="border-2 rounded-lg p-2">
                  <img
                    src={
                      "https://avatars.dicebear.com/api/croodles/" +
                      user.uid +
                      ".svg"
                    }
                    alt="avatar"
                    className="h-28 w-28 rounded-md"
                  />
                </div>
              </div>

              <h2 className="text-3xl font-semibold mt-3">
                {userData?.firstname + " " + userData?.surname}
              </h2>

              <h3 className="text-xl mt-1">{user.email}</h3>
              <h3>
                Verifizierte Fächer:{" "}
                {userData?.verified?.length > 0
                  ? userData?.verified.join(", ")
                  : "keine"}
              </h3>
              <div className="flex flex-col md:flex-row gap-2 mt-5">
                {false && (
                  <button
                    className="btn btn-outline btn-primary"
                    onClick={() => navigate("/become-a-tutor")}
                  >
                    Tutor werden
                  </button>
                )}
               
                <ConfirmModal
                  name="logOutModal"
                  title="Abmelden?"
                  text={"Möchtest du dich wirklich abmelden?"}
                  confirm={logOut}
                ></ConfirmModal>
                <button
                  className="btn btn-outline btn-error"
                  onClick={() => triggerConformModal("logOutModal")}
                >
                  Abmelden
                </button>
                <label
                  className="btn btn-outline btn-error"
                  htmlFor="delete-modal"
                >
                  Account löschen
                </label>
              </div>
              <h1 className="text-error text-lg font-semibold mt-1">
                {errorMessage}
              </h1>

              <input
                type="checkbox"
                id="delete-modal"
                className="modal-toggle"
              />
              <div className="modal">
                <div className="modal-box relative">
                  <label
                    htmlFor="delete-modal"
                    className="btn btn-sm btn-circle absolute right-2 top-2"
                  >
                    ✕
                  </label>
                  <h3 className="text-lg font-bold">Bestätige dein Passwort</h3>
                  <div className="form-control w-full">
                    <label className="label">
                      <span className="label-text">Passwort</span>
                    </label>
                    <input
                      type="password"
                      placeholder="••••••••"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="input input-bordered w-full"
                    />
                  </div>
                  <p className="py-4">
                    Bist du sicher dass du deinen Account und alle damit
                    verbundenen Daten löschen möchtest?
                  </p>
                  <div className="modal-action">
                    <label
                      htmlFor="delete-modal"
                      className="btn btn-error btn-outline"
                      onClick={deleteAccount}
                    >
                      Ja, Account löschen
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card bg-base-100 col-span-2 h-full">
          <div className="card-body">
            <h2 className="card-title">Meine Anfragen</h2>

            <div className="flex flex-col gap-2 overflow-y-scroll overflow-x-clip h-72">
              {elements}
            </div>
          </div>
        </div>
        {userData?.tutor && (
          <div className="card bg-base-100 col-span-2 h-full">
            <div className="card-body">
              <h2 className="card-title">Anfrage von Studenten</h2>

              <div className="flex flex-col gap-2 overflow-y-scroll overflow-x-clip h-60">
                {tutorElements}
              </div>
            </div>
          </div>
        )}
        {userData?.tutor && (
          <div className="card bg-base-100 col-span-3 h-full ">
            <div className="card-body">
              <h2 className="card-title">
                Meine Angebote ({yourOffers?.length}/
                {userData?.verified.length > 0 ? "4" : "2"})
              </h2>
              <div className="flex flex-col md:flex-row h-full gap-2 ">
                {yourOffers}{" "}
                {userData?.verified.length > 0
                  ? yourOffers.length < 4 && <NewOffer></NewOffer>
                  : yourOffers.length < 2 && <NewOffer></NewOffer>}
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};
