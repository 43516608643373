import React, { useEffect, useState } from 'react'
import { useInnerDatabase } from '../../context/InnerDatabaseContext';
import { Store } from "react-notifications-component";
import { useAuth } from '../../context/AuthContext';
import { addDoc, collection, doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { firestore } from '../../config/firebase';

export const RequestButton = ({offer}) => {
    const { myRequests } = useInnerDatabase();
    const [requestExists, setRequestExists] = useState();
    const {user} = useAuth()
    useEffect(() => {
        requestAlreadyExists();
    })
    

    const sendEmail = () => {
        fetch(
          "https://scholarlink-backend.onrender.com/sendEmailRequest?code=c606be38-d5ca-4505-acb0-cc9ba0d37245-357eef1f-024e-43b5-95cc-3845fba86c4c&offerId=" + offer.id
        ).then(res => {
          console.log(res)
          if (res.ok) {
            Store.addNotification({
              title: "Anfrage gesendet",
              message: "Dem Tutor wurde deine Anfrage übermittelt.",
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 3000,
                onScreen: true,
              },
            })
          } else {
              throw new Error("Error: " + res.status);
          }
      })
        
      };


    const requestAlreadyExists = async () => {
        const e = await myRequests.find(
          (r) =>
            r.offerId == offer.id && r.uid === user.uid && r.status === "pending"
        );
        setRequestExists(e != null);
      };

    const sendRequest = async () => {
        if (!requestExists) {
          await addDoc(collection(firestore, "requests"), {
            offerId: offer.id,
            status: "pending",
            tutorId: offer.tutorId,
            uid: user.uid,
            date: serverTimestamp(),
          });
    
          sendEmail();
        } else {
          console.log("no multiple requests");
        }
      };


    const reportOffer = async () => {
        await setDoc(doc(firestore, "reports", user.uid + offer.id), {
          offerId: offer.id,
          tutorId: offer.tutorId,
          user: user.uid,
          title: offer.title,
          description: offer.description,
          date: serverTimestamp(),
        });
    
        Store.addNotification({
          title: "Angebot gemeldet.",
          message: "Vielen Dank für deine Hilfe.",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      };

  return (
    <div className="flex flex-col gap-2 md:w-56">
                {!requestExists ? (
                  <button className="btn btn-primary" onClick={sendRequest}>
                    Anfragen
                  </button>
                ) : (
                  <button className="btn btn-primary btn-disabled">
                    Warten
                  </button>
                )}

                <button className="btn btn-ghost gap-2 " onClick={reportOffer}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.182 16.318A4.486 4.486 0 0012.016 15a4.486 4.486 0 00-3.198 1.318M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z"
                    />
                  </svg>
                  melden
                </button>
              </div>
  )
}
